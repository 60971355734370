import { store } from "../redux/store";
import { updateReduxAction } from "../redux/actions";
import { callGetEndpoint } from "../api-calls/readApiService";
import { isEmptyObject } from "../utilities/helpers";

export async function checkIsProEditMode(projectId: number, unitId: number) {
  const selectedUnit = store.getState().reduxSelectedUnit as any;
  const selectedUnitId = isEmptyObject(selectedUnit) || isEmptyObject(selectedUnit.unit) ? 0 : selectedUnit.unit.id;

  //Check if selectedUnit in redux matches unitId in the URL
  //If not, fetch unit from Graph
  if (`unit-${unitId}` != selectedUnitId) {
    if (!selectedUnit.unitCallInProgress) {
      store.dispatch(updateReduxAction("UPDATE_SELECTED_UNIT", { unitCallInProgress: true }));

      const response = await callGetEndpoint("readapi/getUnit", projectId, unitId);
      if (!response.hasError) {
        store.dispatch(updateReduxAction("UPDATE_SELECTED_UNIT", { unit: response.unit }));
        if (response.unit.isProEditMode == true) {
          return true;
        }
      }
      store.dispatch(updateReduxAction("UPDATE_SELECTED_UNIT", { unitCallInProgress: false }));
    }
  } else {
    if (selectedUnit.unit.isProEditMode == true) {
      return true;
    }
  }
  return false;
}
