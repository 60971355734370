import { store } from '../../redux/store';
import { DrawingViewType } from '../../TypescriptClient';
import { ACCUArrangement } from '../../interfaces/interfaces';

export function getDefaultDrawingView() {
    const config = store.getState().reduxConfigInputs;
    return config.airTunnelOrientation?.value === 1 ? DrawingViewType.TopUpperTunnel : DrawingViewType.Top;
}
export function getAccuArrangement(accuArrangement:any){
    if (accuArrangement === ACCUArrangement[ACCUArrangement.SingleCircuitSideBySide_P1]){
        return "Exhaust";
    }
    if (accuArrangement === ACCUArrangement[ACCUArrangement.SingleCircuitOverUnderTopTunnel_P2]){
      return "Exhaust Turned";
    }
    if (accuArrangement === ACCUArrangement[ACCUArrangement.TwoCircuitSideBySideL_P3]){
      return "Exhaust & End Turned";
    }
    if (accuArrangement === ACCUArrangement[ACCUArrangement.TwoCircuitOverUnderParallel_P4]){
      return "Exhaust Parallel";
    }
    if (accuArrangement === ACCUArrangement[ACCUArrangement.SingleCircuitOverUnderFullHeight_S1]){
      return "End Turned";
    }
    if (accuArrangement === ACCUArrangement[ACCUArrangement.TwoCircuitOverUnderFullHeightParallel_S2]){
      return "End Parallel";
    }
    if (accuArrangement === ACCUArrangement[ACCUArrangement.TwoCircuitSideBySideInSeries_S3]){
      return "Exhaust Series";
    }
    if (accuArrangement === ACCUArrangement[ACCUArrangement.SingleCircuitOverUnderExhaust_M1]){
      return "Exhaust";
    }
    if (accuArrangement === ACCUArrangement[ACCUArrangement.SingleCircuitOverUnderEnd_M2]){
      return "End";
    }
    if (accuArrangement === ACCUArrangement[ACCUArrangement.SingleCircuitSideBySideEndTurned_M3]){
      return "End Turned";
    }
    if (accuArrangement === ACCUArrangement[ACCUArrangement.SingleCircuitSideBySideEnd_M4]){
      return "End";
    }
    if (accuArrangement === ACCUArrangement[ACCUArrangement.TwoCircuitOverUnderExhaustSeries_M5]){
      return "Exhaust Series";
    }
    if (accuArrangement === ACCUArrangement[ACCUArrangement.TwoCircuitOverUnderEndSeries_M6]){
      return "End Series";
    }
    if (accuArrangement === ACCUArrangement[ACCUArrangement.TwoCircuitSideBySideEndParallel_M7]){
      return "End Parallel";
    }
    else if (accuArrangement === ACCUArrangement[ACCUArrangement.SingleCircuitSingleTunnelEnd_P5]){
      return "End";
    }
    else if (accuArrangement === ACCUArrangement[ACCUArrangement.SingleCircuitSingleTunnelEndTurned_M8]){
      return "End Turned";
    }
    else if (accuArrangement === ACCUArrangement[ACCUArrangement.TwoCircuitSingleTunnelEndParallel_P6]){
      return "End Parallel";
    }
    else if (accuArrangement === ACCUArrangement[ACCUArrangement.TwoCircuitSingleTunnelEndSeries_M9]){
      return "End Series";
    }
    else if (accuArrangement === ACCUArrangement[ACCUArrangement.ThreeCircuitSingleTunnelT_T1]){
      return "End Parallel & Turned";
    }
    else if (accuArrangement === ACCUArrangement[ACCUArrangement.FourCircuitSingleTunnelEndParallel_F1]){
      return "End Parallel & Series";
    }
    else if (accuArrangement === ACCUArrangement[ACCUArrangement.ThreeCircuitSideBySide_T2]){
      return "Exhaust & End Parallel";
    }
    else if (accuArrangement === ACCUArrangement[ACCUArrangement.ThreeCircuitSideBySide_T3]){
      return "End Parallel & Turned";
    }
    else if (accuArrangement === ACCUArrangement[ACCUArrangement.FourCircuitSideBySide_F2]){
      return "End Parallel & Series";
    }
    else if (accuArrangement === ACCUArrangement[ACCUArrangement.ThreeCircuitOverUnder_T4]){
      return "End Parallel & Turned";
    }
    else if (accuArrangement === ACCUArrangement[ACCUArrangement.FourCircuitOverUnder_F3]){
      return "End Parallel & Series";
    }

    if (accuArrangement === ACCUArrangement[ACCUArrangement.Automatic]){
      return "Automatic";
    }
  }