import * as React from "react";
import { connect } from "react-redux";
import { match, matchPath } from "react-router-dom";
import { mapDispatchToProps } from "../../redux/actions";
import { DrawingScreen, DrawingViewType} from '../../api-utilities/enums';
import { DrawingViewer } from "./DrawingViewer";
import { callGetEndpoint, updateInputField, getUnitDrawing } from "../../api-calls/readApiService";
import { getValidationError } from "../../utilities/pageUtils";
import "../../css/UnitDrawing.scss";
import { isEmptyArray } from "../../utilities/helpers";

// expected props
interface IProps {
  updateRedux: any;
  match: match<any>;
  history: any;
  reduxMenus: any;
}
// local state
interface IState {
  topDrawingState: IDrawingState;
  bottomDrawingState: IDrawingState;
  renderDefaultDrawing: boolean;
  userInputs: any;
  primaryInputs: any;
  displayFields: any;
  dropdownOptions: any[];
  validationErrors: any[];
  lastUpdatedTextbox: string;
  completeData: string;
  airTunnelOrientationType:string;
  defaultViewType: DrawingViewType;
  topDrawingAvailableViewTypes: DrawingViewType[];
  showPrerequisiteErrors: boolean;
  prerequisiteErrorsList: any[];
  
}

interface IDrawingState {
  svg: string;
  drawingView?: DrawingViewType;
}

class AccessDoors extends React.Component<IProps, IState> {
  public state: IState = {
    topDrawingState: { svg: "", drawingView: undefined },
    bottomDrawingState: { svg: "", drawingView: undefined },
    renderDefaultDrawing: false,
    userInputs: null,
    primaryInputs: null,
    displayFields: null,
    dropdownOptions: [],
    validationErrors: [],
    lastUpdatedTextbox: "",
    completeData:"",
    airTunnelOrientationType:"",
    defaultViewType: DrawingViewType.Top,
    topDrawingAvailableViewTypes: [],
    showPrerequisiteErrors: false,
    prerequisiteErrorsList: []
  };

  async componentDidMount() {
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    if (this.props.reduxMenus.enableSave){
      this.setState({renderDefaultDrawing:true});
    }
    const { projectId, unitId } = this.props.match.params;
    const result = await callGetEndpoint("readapi/GetAccessDoorsValues", projectId, unitId);
    this.setState({
      userInputs: result.uiDataContainer?.fineTuneInputs,
      primaryInputs: result.uiDataContainer?.primaryInputs,
      displayFields: result.uiDataContainer?.displayFields,
      dropdownOptions: result.dropdownOptions,
      validationErrors: result.validationErrors,
      completeData: result.completeData,
      airTunnelOrientationType: result.airTunnelOrientationType,
      defaultViewType: result.defaultViewType,
      topDrawingAvailableViewTypes: result.topDrawingAvailableViewTypes
    });
    //Display prerequisite errors if there are any
    if (!isEmptyArray(result.prerequisiteErrors)) {
      this.setState({ showPrerequisiteErrors: true, prerequisiteErrorsList: result.prerequisiteErrors });
    }
    else {
      await this.getDefaultDrawings();
    }
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });
  }
  componentDidUpdate(prevProps: any) {
    //If a save was in progress, render the default drawings after it finishes.
    // if (prevProps.reduxMenus.enableSave !== this.props.reduxMenus.enableSave && !this.state.renderDefaultDrawing) {
    //   this.getDefaultDrawings();
    // }
  }
  private async getDefaultDrawings() {
    //Get defualt drawing view for Access doors.
    let topDrawingView =
      this.state.topDrawingState.drawingView === undefined
        ? this.state.defaultViewType
        : this.state.topDrawingState.drawingView;
    if (typeof topDrawingView === 'undefined') {
      topDrawingView = this.state.airTunnelOrientationType === "Stacked" ? DrawingViewType.TopUpperTunnel : DrawingViewType.Top;
    }
    await this.selectTopDrawingView(topDrawingView);
    await this.selectBottomDrawingView(this.state.bottomDrawingState.drawingView ?? DrawingViewType.FrontSide);
  }
  private async selectTopDrawingView(newValue: DrawingViewType) {
    this.setState({ topDrawingState: await this.getDrawingState(newValue) });
  }
  private async selectBottomDrawingView(newValue: DrawingViewType) {
    this.setState({ bottomDrawingState: await this.getDrawingState(newValue) });
  }
  //Get the updated SVG
  private async getDrawingState(newValue: number) {
    this.props.updateRedux('UPDATE_DISPLAY', { showLoader: true });
    let completeData = this.state.completeData;
    const response = await getUnitDrawing(this.props.match.params, newValue, DrawingScreen.AccessDoors, completeData);
    let svg = '';
    //let svg = "<svg height=\"300\" viewbox=\"0 0 650 300\" preserveAspectRatio=\"xMidYMid meet\">\r\n  <rect desc=\"unit casing exterior\" x=\"0\" y=\"0\" width=\"640\" height=\"96.75776062334181\" style=\"stroke:black;stroke-width:1;fill:none\" transform=\"translate(10, 101.62111968832909)\" />\r\n  <rect desc=\"primary tunnel interior\" x=\"2.1990400141668593\" y=\"49.47840031875433\" width=\"630.601919971667\" height=\"45.080320290420616\" style=\"stroke:black;stroke-width:1;fill:none;stroke-dasharray:10 5\" transform=\"translate(10, 101.62111968832909)\" />\r\n  <rect desc=\"secondary tunnel interior\" x=\"2.1990400141668593\" y=\"2.1990400141668593\" width=\"6398.601919971667\" height=\"45.080320290420616\" style=\"stroke:black;stroke-width:1;fill:none;stroke-dasharray:10 5\" transform=\"translate(10, 101.62111968832909)\" />\r\n</svg>";
    if (response?.data.drawings?.length) {
      svg = response?.data.drawings[0].unitDrawingSvg;
    }
    this.props.updateRedux('UPDATE_DISPLAY', { showLoader: false });
    return { svg: svg ?? '', drawingView: newValue } as IDrawingState;
  }

  private getTopDrawingViewer() {
    return (
      <DrawingViewer
        drawingScreen={DrawingScreen.AccessDoors}
        availableViewTypes={this.state.topDrawingAvailableViewTypes ?? []}
        drawingViewInfo={this.state.topDrawingState}
        selectDrawingView={this.selectTopDrawingView.bind(this)}
      ></DrawingViewer>
    );
  }
  private getBottomDrawingViewer() {
    return (
      <DrawingViewer
        drawingScreen={DrawingScreen.AccessDoors}
        availableViewTypes={[DrawingViewType.FrontSide, DrawingViewType.BackSide]}
        drawingViewInfo={this.state.bottomDrawingState}
        selectDrawingView={this.selectBottomDrawingView.bind(this)}
      ></DrawingViewer>
    );
  }
  public checkForErrors(fieldName: string) {
    const errors = this.state.validationErrors;
    if (errors) {
      const hasError = errors.find((x: any) => x.fieldName.toLowerCase() === fieldName.toLowerCase() && x.suppressErrorInUi !== true);
      return hasError ? true : false;
    }
    return false;
  }
  //Check for errors on this textbox/selectbox, and return the appropriate CSS className
  public getInputCSS(fieldName: string, type: string) {
    const hasError = this.checkForErrors(fieldName);

    let className = "";
    if (type === "textbox") {
      className = "input-numeric";
    }
    if (type === "selectbox") {
      className = "input-select";
    }
    //If error exists, add the error class
    if (hasError) {
      className += " input-error";
    }
    return className;
  }
  //------------------------------------------------------------------------------------------
  //Save new value to redis and DB
  public async saveInputValue(fieldName: string, newValue: string, targetVertex: string) {
    const { projectId, unitId } = this.props.match.params;
    this.removeValidationError(fieldName);
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    let result = await updateInputField("updateAccessDoors", projectId, unitId, fieldName, newValue, targetVertex);
    if (result.success) {
      //If there are any errors in the response, add them to state
      if (result.data.validationErrors != null) {
        this.addValidationErrors(result.data.validationErrors);
      }

      //If there are any dropdownLists present in the response, then update those in state
      if (result.data.dropdownOptions != null) {
        this.updateDropdowns(result.data.dropdownOptions);
      }

      //If unitBasicsData was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        this.setState({
          userInputs: result.data.uiDataContainer.fineTuneInputs,
          displayFields: result.data.uiDataContainer.displayFields,
          completeData: result.data.completeData
        });
        this.getDefaultDrawings();
      }
    }
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });
  }
  //Whenever we update a field, remove any previous error for that field
  //Also reset the lastUpdatedTextbox in state
  public removeValidationError(fieldName: string) {
    let errorsArray = [...this.state.validationErrors];

    //Make a new array that only includes items not matching the given fieldName
    let errors = errorsArray.filter((item: any) => item.fieldName.toLowerCase() !== fieldName.toLowerCase());

    this.setState({ validationErrors: errors, lastUpdatedTextbox: "" });
  }

  //Update dropdowns with new options from the server
  public updateDropdowns(newDropdownOptions: any) {
    //Make a copy of the list from state.dropdownOptions
    const newState = Object.assign({}, this.state.dropdownOptions, {});

    //Update newState with any new values we got from the server
    Object.entries(newDropdownOptions).forEach(([key, value]) => {
      if (value != null) {
        (newState as any)[key] = value;
      }
    });

    this.setState({ dropdownOptions: newState });
  }

  //Add any validation errors we got from the server to state
  public addValidationErrors(validationErrors: any[]) {
    //make a copy of error messages collection in state
    let errorsArray = [...this.state.validationErrors];

    for (const error of validationErrors) {
      const exists = this.state.validationErrors.find((x: any) => x.fieldName.toLowerCase() === error.fieldName.toLowerCase());
      if (!exists) {
        errorsArray.push(error);
      }
    }
    this.setState({ validationErrors: errorsArray });
  }
  public getSelectBoxOptions(boxName: any) {
    const options = (this.state.dropdownOptions as any)[boxName];
    return options.map((item: any, index: number) => (
      <option key={index} value={item.textValue}>
        {item.displayValue}
      </option>
    ));
  }

  public getValidationError(fieldName: string) {
    //Call the validation method from pageUtils
    return getValidationError(this.state.validationErrors, fieldName, "validation-error0");
  }
  private getPrerequisiteErrors() {
    const errors = this.state.prerequisiteErrorsList;
    if (errors) {
      return errors.map((item: any, index: number) => (
        <div key={index}>
          <div className="opt-err1">{item.source}</div>
          <div className="opt-err2">{item.errorMessage}</div>
        </div>
      ));
    }
    return null;
  }
  public render() {
    if (this.state.showPrerequisiteErrors) {
      return (
        <div className="opt-prereq">
          <div className="opt-prereq-title">Warning</div>
          <div className="opt-prereq-msg">Design calculations cannot be run due to missing/invalid fields:</div>
          <div className="opt-prereq-heading">
            <div>Page</div>
            <div>Field</div>
          </div>
          {this.getPrerequisiteErrors()}
          <div className="opt-spacer"></div>

          <div>Please revisit these page(s) and fix any invalid inputs.</div>
        </div>
      );
    }
    const savedValues = this.state.userInputs;
    if (!savedValues) {
      return null;
    }

    return (
      <div className="drawing-inputs-main">
        <div className="drawing-container">
          <div className="drawing-left">
            <div className="drawing-fields-left">
              <fieldset className="drawing-fieldset doors-fieldset legend-spacer">
                <legend className="drawing-legend doors-legend">Overall Door Plan</legend>

                <div className="drawing-fields-spacer"></div>

                <div className="input-fields">
                  <div className="accordion-row">
                    <div className="accordion-label">Access Side</div>
                    <div className="accordion-input">
                      <select
                        className={this.getInputCSS("unitAccessSide", "accordion-select")}
                        value={savedValues.unitAccessSide}
                        onChange={(event) => this.saveInputValue("unitAccessSide", event.currentTarget.value, "FineTuneInputs")}
                      >
                        {this.getSelectBoxOptions("unitAccessSideOptions")}
                      </select>
                      {this.getValidationError("unitAccessSide")}
                    </div>
                  </div>
                </div>
                <div className="drawing-fields-spacer"></div>
                <div className="drawing-fields-spacer"></div>
              </fieldset>
            </div>
          </div>

          <div className="drawing-right">
            <div className="drawing-heading">Unit Drawing Preview</div>
              {this.getTopDrawingViewer()}
              {this.getBottomDrawingViewer()}
          </div>

          <div className="drawing-td3">{/* Take up remaining horizontal space in browser */}</div>
        </div>
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxMenus: state.reduxMenus
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AccessDoors);
