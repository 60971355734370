import * as React from "react";
import { connect } from "react-redux";
import { Route, match } from "react-router-dom";
import LayoutSubMenu from "./Menus/LayoutSubMenu";
import AccessDoors from "./FineTuneDesign/AccessDoors";
import CoilConnections from "./FineTuneDesign/CoilConnections";
import LayoutAdjustments from "./FineTuneDesign/LayoutAdjustments";
import LengthInputs from "./FineTuneDesign/LengthInputs";
import InletOutletSizing from "./FineTuneDesign/InletOutletSizing";
import { mapDispatchToProps } from "../redux/actions";
import { checkIsProEditMode } from "../utilities/specmateProHelpers";
import "../css/UnitSelection.scss";

// expected props
interface IProps {
  updateRedux: any;
  match: match<any>;
  history: any;
}
// local state
interface IState {}

class UnitLayout extends React.Component<IProps, IState> {
  componentDidMount() {
    this.props.updateRedux("UPDATE_MENUS", { topMenu: { activeItem: 4 } });
  }

  async componentDidUpdate() {
    const { projectId, unitId } = this.props.match.params;
    let isProEditMode = await checkIsProEditMode(projectId, unitId);

    if (isProEditMode) {
      this.props.history.push("/project"); //redirect to project page
    }
  }

  public render() {
    return (
      <div className="app-body-content">
        <LayoutSubMenu></LayoutSubMenu>
        <Route path="/pid/:projectId/uid/:unitId/layout/doors" component={AccessDoors} />
        <Route path="/pid/:projectId/uid/:unitId/layout/coil" component={CoilConnections} />
        <Route path="/pid/:projectId/uid/:unitId/layout/panels" component={LayoutAdjustments} />
        <Route path="/pid/:projectId/uid/:unitId/layout/length" component={LengthInputs} />
        <Route path="/pid/:projectId/uid/:unitId/layout/sizing" component={InletOutletSizing} />
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(UnitLayout);
