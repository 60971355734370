import * as React from "react";
import { connect } from "react-redux";
import { match, matchPath } from "react-router-dom";
import { mapDispatchToProps } from "../../redux/actions";
import { DrawingScreen, DrawingViewType } from "../../api-utilities/enums";
import { addNotification, removeNotification } from "../../api-utilities/notification-tools";
import "../../css/UnitDrawing.scss";
import { DrawingViewer } from "./DrawingViewer";
import { resetReduxModals,isEmptyArray } from "../../utilities/helpers";
import { getAccuArrangement } from "./UnitLayoutUtil";
import { callGetEndpoint, updateInputField, getUnitDrawing } from "../../api-calls/readApiService";
import { getValidationError, getInputCSS, getSelectBoxOptions } from "../../utilities/pageUtils";

// expected props
interface IProps {
  updateRedux: any;
  match: match<any>;
  history: any;
  reduxMenus: any;
  reduxModals: any;
}

// local state
interface IState {
  userInputs: any;
  displayFields: any;
  refrigeratorDisplayFields: any;
  refrigerationUserInputs: any;
  dropdownOptions: any[];
  validationErrors: any[];
  lastUpdatedTextbox: string;
  topDrawingState: IDrawingState;
  bottomDrawingState: IDrawingState;
  renderDefaultDrawing: boolean;
  showUnitWidthTooltip: boolean;
  showUnitHeightTooltip: boolean;
  showPrerequisiteErrors: boolean;
  prerequisiteErrorsList: any[];
  unit: any;
  completeData: string;
  airTunnelOrientationType: string;
  defaultViewType: DrawingViewType;
  topDrawingAvailableViewTypes: any[];
  pageValidationErrors: string[];
  hoodAndLouverExtensions: any;
}

interface IDrawingState {
  svg: string;
  drawingView?: DrawingViewType;
}

class LayoutAdjustments extends React.Component<IProps, IState> {
  public state: IState = {
    topDrawingState: { svg: "", drawingView: undefined },
    bottomDrawingState: { svg: "", drawingView: undefined },
    renderDefaultDrawing: false,
    showUnitWidthTooltip: false,
    showUnitHeightTooltip: false,
    userInputs: null,
    refrigerationUserInputs: null,
    refrigeratorDisplayFields: null,
    displayFields: null,
    dropdownOptions: [],
    validationErrors: [],
    lastUpdatedTextbox: "",
    showPrerequisiteErrors: false,
    prerequisiteErrorsList: [],
    unit: null,
    completeData: "",
    airTunnelOrientationType: "",
    defaultViewType: DrawingViewType.Top,
    topDrawingAvailableViewTypes: [],
    pageValidationErrors: [],
    hoodAndLouverExtensions: []
  };

  async componentDidMount() {
    const { projectId, unitId } = this.props.match.params;

    //Get FineTuneInput values for Electrical/Control/Furnace Panels
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    const result = await callGetEndpoint("readapi/GetLayoutAdjustmentValues", projectId, unitId);

    this.setState({
      userInputs: result.uiDataContainer?.fineTuneInputs,
      displayFields: result.uiDataContainer?.displayFields,
      dropdownOptions: result.dropdownOptions,
      validationErrors: result.validationErrors,
      prerequisiteErrorsList: result.prerequisiteErrors,
      refrigerationUserInputs: result.refrigerationUIDataContainer?.refrigerationInputs,
      refrigeratorDisplayFields: result.refrigerationUIDataContainer?.displayFields,
      unit: result.unit,
      completeData: result.completeData,
      airTunnelOrientationType: result.airTunnelOrientationType,
      defaultViewType: result.defaultViewType,
      topDrawingAvailableViewTypes: result.topDrawingAvailableViewTypes,
      pageValidationErrors: result.individualErrors,
      hoodAndLouverExtensions: result.hoodLouverExtensionInfo
    });
    //Display prerequisite errors if there are any
    if (!isEmptyArray(result.prerequisiteErrors)) {
      this.setState({ showPrerequisiteErrors: true, prerequisiteErrorsList: result.prerequisiteErrors });
    }
    else {
      await this.getDefaultDrawings();
      this.showErrorModal("layoutadjustmentspage");
    }
    
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });
  }
  private async getDefaultDrawings() {
    //Get defualt drawing view for Electrical Panels.
    let topDrawingView = this.state.topDrawingState.drawingView === undefined ? this.state.defaultViewType : this.state.topDrawingState.drawingView;
    if (typeof topDrawingView === "undefined") {
      topDrawingView = this.state.airTunnelOrientationType === "Stacked" ? DrawingViewType.TopUpperTunnel : DrawingViewType.Top;
    }
    await this.selectTopDrawingView(topDrawingView);
    await this.selectBottomDrawingView(this.state.bottomDrawingState.drawingView ?? DrawingViewType.FrontSide);
  }
  //Get the updated SVG
  private async getDrawingState(newValue: number) {
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    let completeData = this.state.completeData;
    const response = await getUnitDrawing(this.props.match.params, newValue, DrawingScreen.ElectricalFurnacePanels, completeData);
    let svg = "";
    //let svg = "<svg height=\"300\" viewbox=\"0 0 650 300\" preserveAspectRatio=\"xMidYMid meet\">\r\n  <rect desc=\"unit casing exterior\" x=\"0\" y=\"0\" width=\"640\" height=\"96.75776062334181\" style=\"stroke:black;stroke-width:1;fill:none\" transform=\"translate(10, 101.62111968832909)\" />\r\n  <rect desc=\"primary tunnel interior\" x=\"2.1990400141668593\" y=\"49.47840031875433\" width=\"630.601919971667\" height=\"45.080320290420616\" style=\"stroke:black;stroke-width:1;fill:none;stroke-dasharray:10 5\" transform=\"translate(10, 101.62111968832909)\" />\r\n  <rect desc=\"secondary tunnel interior\" x=\"2.1990400141668593\" y=\"2.1990400141668593\" width=\"6398.601919971667\" height=\"45.080320290420616\" style=\"stroke:black;stroke-width:1;fill:none;stroke-dasharray:10 5\" transform=\"translate(10, 101.62111968832909)\" />\r\n</svg>";
    if (response?.data.drawings?.length) {
      svg = response?.data.drawings[0].unitDrawingSvg;
    }
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });
    return { svg: svg ?? "", drawingView: newValue } as IDrawingState;
  }
  private async selectTopDrawingView(newValue: DrawingViewType) {
    this.setState({ topDrawingState: await this.getDrawingState(newValue) });
  }
  private async selectBottomDrawingView(newValue: DrawingViewType) {
    this.setState({ bottomDrawingState: await this.getDrawingState(newValue) });
  }

  private getTopDrawingViewer() {
    return (
      <DrawingViewer
        drawingScreen={DrawingScreen.ElectricalFurnacePanels}
        availableViewTypes={this.state.topDrawingAvailableViewTypes ?? []}
        drawingViewInfo={this.state.topDrawingState}
        selectDrawingView={this.selectTopDrawingView.bind(this)}
      ></DrawingViewer>
    );
  }
  private getBottomDrawingViewer() {
    return (
      <DrawingViewer
        drawingScreen={DrawingScreen.ElectricalFurnacePanels}
        availableViewTypes={[DrawingViewType.FrontSide, DrawingViewType.BackSide]}
        drawingViewInfo={this.state.bottomDrawingState}
        selectDrawingView={this.selectBottomDrawingView.bind(this)}
      ></DrawingViewer>
    );
  }

  //------------------------------------------------------------------------------------------
  //Save new value to redis and DB
  public async SaveRefrigerationInputValue(fieldName: string, newValue: string) {
    const { projectId, unitId } = this.props.match.params;
    this.removeValidationError(fieldName);
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    let result = await updateInputField("updateRefrigeration", projectId, unitId, fieldName, newValue, "RefrigerationInputs");
    if (result.success) {
      //If there are any errors in the response, add them to state
      if (result.data.validationErrors != null) {
        this.addValidationErrors(result.data.validationErrors);
      }

      if (result.data.uiDataContainer != null) {
        this.setState({
          refrigerationUserInputs: result.data.uiDataContainer.refrigerationInputs,
          pageValidationErrors: result.data.individualErrors,
          completeData: result.data.completeData,
          unit: result.data.completeData.unit
          //NOTE: don't update displayFields when updating manualAccu.
          //DisplayFields get returned at pageLoad and the manualAccu update doesn't recalculate them.
        });
        this.getDefaultDrawings();
        this.showErrorModal("layoutadjustmentspage");
      }
    }
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });
  }
  public async saveInputValue(fieldName: string, newValue: string) {
    const { projectId, unitId } = this.props.match.params;
    //Control Panel Location or Control Panel Mounting Face.
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });

    this.removeValidationError(fieldName);

    let result = await updateInputField("updateLayout", projectId, unitId, fieldName, newValue, "FineTuneInputs");

    if (result.success) {
      //If there are any errors in the response, add them to state
      if (result.data.validationErrors != null) {
        this.addValidationErrors(result.data.validationErrors);
      }

      //If there are any dropdownLists present in the response, then update those in state
      if (result.data.dropdownOptions != null) {
        this.updateDropdowns(result.data.dropdownOptions);
      }

      //Update state.
      if (result.data.uiDataContainer != null) {
        if (result.data.completeData != null) {
          this.setState({
            userInputs: result.data.uiDataContainer.fineTuneInputs,
            displayFields: result.data.uiDataContainer.displayFields,
            pageValidationErrors: result.data.individualErrors,
            completeData: result.data.completeData,
            unit: result.data.unit
          });
          this.getDefaultDrawings();
        } else {
          this.setState({
            userInputs: result.data.uiDataContainer.fineTuneInputs,
            displayFields: result.data.uiDataContainer.displayFields,
            pageValidationErrors: result.data.individualErrors,
            unit: result.data.unit
          });
        }
        this.showErrorModal("layoutadjustmentspage");
      }
    }
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });
  }
  //Whenever we update a field, remove any previous error for that field
  //Also reset the lastUpdatedTextbox in state
  public removeValidationError(fieldName: string) {
    let errorsArray = [...this.state.validationErrors];

    //Make a new array that only includes items not matching the given fieldName
    let errors = errorsArray.filter((item: any) => item.fieldName.toLowerCase() !== fieldName.toLowerCase());

    this.setState({ validationErrors: errors, lastUpdatedTextbox: "" });
  }

  //Update dropdowns with new options from the server
  public updateDropdowns(newDropdownOptions: any) {
    //Make a copy of the list from state.dropdownOptions
    const newState = Object.assign({}, this.state.dropdownOptions, {});

    //Update newState with any new values we got from the server
    Object.entries(newDropdownOptions).forEach(([key, value]) => {
      if (value != null) {
        (newState as any)[key] = value;
      }
    });

    this.setState({ dropdownOptions: newState });
  }

  //Add any validation errors we got from the server to state
  public addValidationErrors(validationErrors: any[]) {
    //make a copy of error messages collection in state
    let errorsArray = [...this.state.validationErrors];

    for (const error of validationErrors) {
      const exists = this.state.validationErrors.find((x: any) => x.fieldName.toLowerCase() === error.fieldName.toLowerCase());
      if (!exists) {
        errorsArray.push(error);
      }
    }
    this.setState({ validationErrors: errorsArray });
  }

  public getValidationError(fieldName: string) {
    //Call the validation method from pageUtils
    return getValidationError(this.state.validationErrors, fieldName, "validation-error0");
  }
  private getPrerequisiteErrors() {
    const errors = this.state.prerequisiteErrorsList;
    if (errors) {
      return errors.map((item: any, index: number) => (
        <div key={index}>
          <div className="opt-err1">{item.source}</div>
          <div className="opt-err2">{item.errorMessage}</div>
        </div>
      ));
    }
    return null;
  }
  public render() {
    if (this.state.showPrerequisiteErrors) {
      return (
        <div className="opt-prereq">
          <div className="opt-prereq-title">Warning</div>
          <div className="opt-prereq-msg">Design calculations cannot be run due to missing/invalid fields:</div>
          <div className="opt-prereq-heading">
            <div>Page</div>
            <div>Field</div>
          </div>
          {this.getPrerequisiteErrors()}
          <div className="opt-spacer"></div>

          <div>Please revisit these page(s) and fix any invalid inputs.</div>
        </div>
      );
    }
    const savedValues = this.state.userInputs;
    const refrigerationSavedValues = this.state.refrigerationUserInputs;
    if (!savedValues) {
      return null;
    }
    const displayFields = this.state.displayFields;

    return (
      <div className="drawing-inputs-main">
        <div className="drawing-container">
          <div className="drawing-left">
            <div className="drawing-fields-left">
              {/* ELECTRICAL/CONTROL PANEL */}
              <fieldset className="drawing-fieldset-layout-adjustment elect-fieldset legend-spacer">
                <legend className="drawing-legend elect-legend">Electrical/Control Panel</legend>

                <div className="drawing-fields-spacer"></div>

                <div className="input-fields">
                  <div className="accordion-row">
                    <div className="accordion-label-95">Mounting</div>
                    <div className="accordion-input">
                      <select
                        className={getInputCSS(this.state.validationErrors, "electricalPanelMountingFace", "accordion-select")}
                        value={savedValues.electricalPanelMountingFace}
                        onChange={(event) => this.saveInputValue("electricalPanelMountingFace", event.currentTarget.value)}
                      >
                        {getSelectBoxOptions(this.state.dropdownOptions, "electricalPanelMountingFaceOptions")}
                      </select>
                    </div>
                  </div>

                  <div className="accordion-row">
                    <div className="accordion-label-95">Location</div>
                    <div className="accordion-input">
                      <select
                        className={getInputCSS(this.state.validationErrors, "electricalPanelLocation", "accordion-select")}
                        value={savedValues.electricalPanelLocation}
                        onChange={(event) => this.saveInputValue("electricalPanelLocation", event.currentTarget.value)}
                      >
                        {getSelectBoxOptions(this.state.dropdownOptions, "electricalPanelLocationOptions")}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="drawing-fields-spacer"></div>
                <div className="drawing-fields-spacer"></div>
              </fieldset>

              {/* FURNACE PANEL */}
              {displayFields.showFurnacePanel ? (
                <fieldset className="drawing-fieldset-layout-adjustment furnace-fieldset legend-spacer">
                  <legend className="drawing-legend furnace-legend">Furnace Panel</legend>

                  <div className="drawing-fields-spacer"></div>

                  <div className="input-fields">
                    <div className="accordion-row">
                      <div className="accordion-label-95">Location</div>
                      <div className="accordion-input">
                        <select
                          className={getInputCSS(this.state.validationErrors, "furnacePanelLocation", "accordion-select")}
                          value={savedValues.furnacePanelLocation}
                          onChange={(event) => this.saveInputValue("furnacePanelLocation", event.currentTarget.value)}
                        >
                          {getSelectBoxOptions(this.state.dropdownOptions, "furnacePanelLocationOptions")}
                        </select>
                        {this.getValidationError("furnacePanelLocation")}
                      </div>
                    </div>
                  </div>

                  <div className="drawing-fields-spacer"></div>
                  <div className="drawing-fields-spacer"></div>
                </fieldset>
              ) : null}

              {/* PACKAGED REFRIGERATION */}
              {displayFields.showPackagedRefrigeration ? (
                <fieldset className="drawing-fieldset-layout-adjustment refrigeration-fieldset legend-spacer">
                  <legend className="drawing-legend refrigeration-legend">Packaged Refrigeration</legend>

                  <div className="drawing-fields-spacer"></div>

                  <div className="input-fields">
                    <div className="accordion-row">
                      <div className="accordion-label-95">Location</div>
                      <div className="accordion-input-175">
                        <div className="refrig-location">
                          <input disabled={true} type="text" value={getAccuArrangement(refrigerationSavedValues.manualACCUArrangement)} />
                        </div>
                      </div>
                      <div className="accordion-input-25">
                        <div className="proj-20 proj-link edit-link" onClick={(event) => this.openRefrigerationLocationModal(event)}>
                          Edit
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="drawing-fields-spacer"></div>
                  <div className="drawing-fields-spacer"></div>
                </fieldset>
              ) : null}

              {/* UNIT DIMENSIONS */}
              <fieldset className="drawing-fieldset-layout-adjustment unit-dimensions-fieldset legend-spacer">
                <legend className="drawing-legend unit-dimensions-legend">Unit Dimensions</legend>

                <div className="drawing-fields-spacer"></div>

                <div className="input-fields">
                  <label className="dimensions-label">Cabinet & Base Height: {this.state.unit.casingHeight || "n/a"}"</label>
                  <label className="dimensions-label">Cabinet Width: {this.state.unit.casingWidth || "n/a"}"</label>
                  <label className="dimensions-label">Cabinet Length: {this.state.unit.casingLength || "n/a"}"</label>
                  <div className="drawing-fields-spacer"></div>
                  <label className="dimensions-label">Overall Height: {this.state.unit.overallHeight || "n/a"}"</label>
                  <label className="dimensions-label">
                    Overall Width: {this.state.unit.overallWidth || "n/a"}"
                    <span
                      className="dimensions-info"
                      onMouseEnter={() => this.showTooltip("width", true)}
                      onMouseLeave={() => this.showTooltip("width", false)}
                    ></span>
                    {this.getTooltipContent("width")}
                  </label>
                  <label className="dimensions-label">
                    Overall Length: {this.state.unit.overallLength || "n/a"}"
                    <span
                      className="dimensions-info"
                      onMouseEnter={() => this.showTooltip("length", true)}
                      onMouseLeave={() => this.showTooltip("length", false)}
                    ></span>
                    {this.getTooltipContent("length")}
                  </label>
                </div>

                <div className="drawing-fields-spacer"></div>
                <div className="drawing-fields-spacer"></div>
              </fieldset>
            </div>
          </div>

          <div className="drawing-right">
            <div className="drawing-heading">Unit Drawing Preview</div>

            {this.getTopDrawingViewer()}

            {this.getBottomDrawingViewer()}
          </div>

          <div className="drawing-td3">{/* Take up remaining horizontal space in browser */}</div>
        </div>
      </div>
    );
  }
  openRefrigerationLocationModal(event: React.SyntheticEvent<HTMLDivElement>) {
    //User clicked Edit link on Packaged Refrigeration Section.  Don't fire the click event attached to that bar.
    event.stopPropagation();
    const modalData = {
      displayFields: this.state.refrigeratorDisplayFields,
      ACCUArrangement: this.state.refrigerationUserInputs.manualACCUArrangement
    };
    //This triggers ModalContainer.tsx to open the modal
    //Should pass the selected location, orientation, and number of circuits.
    this.props.updateRedux("UPDATE_MODALS", {
      showModal: true,
      modalType: "RefrigerationLocationModal",
      modalData: modalData,
      modalId: "refrigeration-location"
    });
  }
  private showErrorModal(notificationId: string) {
    const pageErrors = this.state.pageValidationErrors;
    if (pageErrors && pageErrors.length > 0) {
      var errorString = pageErrors.join(", ");
      const notification = {
        id: notificationId,
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">{errorString}</div>
      };
      addNotification(notification);
    } else {
      removeNotification(notificationId);
    }
  }

  //Show/Hide tooltips on mouseover
  private showTooltip(tooltip: "width" | "length", state: boolean) {
    if (tooltip === "width") {
      this.setState({ showUnitWidthTooltip: state });
    } else {
      this.setState({ showUnitHeightTooltip: state });
    }
  }
  //Get display content for each tooltip
  private getTooltipContent(tooltip: string) {
    const tooltipData = this.state.hoodAndLouverExtensions;;
    if (tooltipData) {
      const tooltipItems = this.getTooltipListItems(tooltipData);
      const showTooltip = tooltip === "width" ? this.state.showUnitWidthTooltip : this.state.showUnitHeightTooltip;
      return (
        <div className={showTooltip ? "dimensions-tooltip1" : "app-display-none"}>
          <div className="dimensions-tooltip-content">
            The Overall Width and Overall Length values account for unit features such as roof overhang, electrical
            panels, and lifting lugs but do not include hoods and louvers{isEmptyArray(tooltipItems) ? '.' : ':'}
            <br />
            <ul> {tooltipItems}</ul>
          </div>
        </div>
      );
    }
  }
  //hoodAndLouverExtensions comes from the server as an object with a dynamic list of properties.
  //so we need to loop through it, break each property down and get the pieces we need.
  private getTooltipListItems(tooltipData: any) {
    return tooltipData.map((item: any, index: number) => (
      <li key={index} className="dimensions-li">
        {item}
      </li>
     ));
  }
  //Check for user response to the delete-project modal or the addNewProject modal.
  async componentDidUpdate(prevProps: IProps, prevState: IState) {
    //If a save was in progress, render the default drawings after it finishes.
    // if (prevProps.reduxMenus.enableSave !== this.props.reduxMenus.enableSave && !this.state.renderDefaultDrawing) {
    //   this.getDefaultDrawings();
    // }
    const modal = this.props.reduxModals;

    //If modal was open but is now closed, check what user's response was.
    if (prevProps.reduxModals.showModal !== modal.showModal && modal.showModal === false) {
      if (modal.modalId === "refrigeration-location") {
        if (modal.userResponse.response === "ok") {
          this.SaveRefrigerationInputValue("manualACCUArrangement", modal.userResponse.accuArrangementSelected);
        }
      }
      resetReduxModals();
    }
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxMenus: state.reduxMenus,
    reduxModals: state.reduxModals
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(LayoutAdjustments);
