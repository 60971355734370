import * as React from "react";
import { connect } from "react-redux";
import { mapDispatchToProps } from "../../redux/actions";
import { match } from "react-router";
import { callGetEndpoint, getInletOutletSizingDrawing, updateInputField } from "../../api-calls/readApiService";
import {
  getValidationError,
  getSelectBoxOptions,
  getInputCSS,
  getFilteredValidationErrors,
  getNewDropdownList,
  getNewValidationErrorsList
} from "../../utilities/pageUtils";
import AccordionHeader from "../Shared/AccordionHeader";
import { isEmptyArray } from "../../utilities/helpers";
import { formatNumber } from "../../api-utilities/formatting";
import { addNotification, removeNotification } from "../../api-utilities/notification-tools";
import "../../css/UnitSizing.scss";

// expected props
interface IProps {
  updateRedux: any;
  match: match<any>;
}
// local state
interface IState {
  userInputs: any;
  displayFields: any;
  dropdownOptions: any[];
  validationErrors: any[];
  lastUpdatedTextbox: string;
  drawingSVG: string;
  showPrerequisiteErrors: boolean;
  prerequisiteErrorsList: any[];
  individualErrors: any[];
  activeComponent: string;
  completeData: string;
  activeId: string;
}

class UnitSizing extends React.Component<IProps, IState> {
  public state: IState = {
    userInputs: null,
    displayFields: null,
    dropdownOptions: [],
    validationErrors: [],
    lastUpdatedTextbox: "",
    drawingSVG: "",
    showPrerequisiteErrors: false,
    prerequisiteErrorsList: [],
    individualErrors: [],
    completeData: "",
    activeComponent: "SupplyAirOutlet",
    activeId: "SAOutlet"
  };

  async componentDidMount() {
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    const { projectId, unitId } = this.props.match.params;
    const result = await callGetEndpoint("readapi/GetInletOutletSizingValues", projectId, unitId);
    this.setState({
      userInputs: result.uiDataContainer?.primaryInputs,
      displayFields: result.uiDataContainer?.displayFields,
      dropdownOptions: result.dropdownOptions,
      validationErrors: result.validationErrors,
      individualErrors: result.individualErrors,
      completeData: result.completeData
    });

    //Display prerequisite errors if there are any
    if (!isEmptyArray(result.prerequisiteErrors)) {
      this.setState({ showPrerequisiteErrors: true, prerequisiteErrorsList: result.prerequisiteErrors });
    }
    else {
      if (!isEmptyArray(result.individualErrors)) {
        //display the nice error dialog box
        const notification = {
          id: "inletOutletSizingError",
          notificationType: "error",
          title: "Error",
          content: <div className="nf-div">{result.individualErrors.toString()}</div>
        };
        addNotification(notification);
      }
  
      this.getDrawingSvg();
    }

    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });
  }
  private async getDrawingSvg() {
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    const response = await getInletOutletSizingDrawing(this.props.match.params, this.state.activeId, this.state.completeData);
    if (response?.data.schematicSvg?.length) {
      this.setState({ drawingSVG: response.data.schematicSvg });
    } else {
      this.setState({ drawingSVG: "" });
    }
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });
  }
  getSketchTitle() {
    switch (this.state.activeId) {
      case "OAInlet":
        return "Outside Air Inlet";
      case "SAOutlet":
        return "Supply Air Outlet";
      case "RAInlet":
        return "Return Air Inlet";
      case "EAOutlet":
        return "Exhaust Air Outlet";
    }
    return "";
  }
  getConnectionLocation() {
    const primaryInputs = this.state.userInputs;
    switch (this.state.activeId) {
      case "OAInlet":
        return this.convertToInletOutletLocationToText(primaryInputs.outsideAirInletLocation);
      case "SAOutlet":
        return this.convertToInletOutletLocationToText(primaryInputs.supplyAirOutletLocation);
      case "RAInlet":
        return this.convertToInletOutletLocationToText(primaryInputs.returnAirInletLocation);
      case "EAOutlet":
        return this.convertToInletOutletLocationToText(primaryInputs.exhaustAirOutletLocation);
    }
    return "";
  }
  convertToInletOutletLocationToText(inletOutletLocation: string){
    switch (inletOutletLocation) {
      case "LeftEnd":
        return "Left End";
      case "RightEnd":
        return "Right End";
      case "FrontSide":
        return "Front Side";
      case "BackSide":
        return "Back Side";
    }
    return inletOutletLocation;
  }
  //This function gets called on every keystroke in a textbox.
  public async updateTextboxValue(fieldName: string, newValue: string) {
    const newState = Object.assign({}, this.state.userInputs, { [fieldName]: newValue });
    this.setState({ userInputs: newState, lastUpdatedTextbox: fieldName });
  }

  //This gets called by textbox onBlur events
  public async saveTextboxValue(fieldName: string, newValue: string) {
    //If field value hasn't changed, don't call the server
    if (this.state.lastUpdatedTextbox !== fieldName) {
      return;
    }
    this.saveInputValue(fieldName, newValue);
  }

  //Save new value to redis and DB
  public async saveInputValue(fieldName: string, newValue: string) {
    const { projectId, unitId } = this.props.match.params;
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    this.removeValidationError(fieldName);
    let result = await updateInputField("updateInletOutletSizing", projectId, unitId, fieldName, newValue, "primaryInputs");

    if (result.success) {
      //If there are any errors in the response, add them to state
      if (result.data.validationErrors != null) {
        this.addValidationErrors(result.data.validationErrors);
      }

      //If there are any dropdownLists present in the response, then update those in state
      if (result.data.dropdownOptions != null) {
        this.updateDropdowns(result.data.dropdownOptions);
      }

      //If uiDataContainer was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        this.setState({
          userInputs: result.data.uiDataContainer.primaryInputs,
          displayFields: result.data.uiDataContainer.displayFields,
          completeData: result.data.completeData
        });
      }
      this.getDrawingSvg();
    }
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });
  }

  //Check for errors on this textbox/selectbox, and return the appropriate CSS className
  public getCSS(fieldName: string, type: string) {
    return getInputCSS(this.state.validationErrors, fieldName, type);
  }

  //Update dropdowns with new options from the server
  public updateDropdowns(newDropdownOptions: any) {
    let newList = getNewDropdownList(this.state.dropdownOptions, newDropdownOptions);
    this.setState({ dropdownOptions: newList });
  }

  //Add any validation errors we got from the server to state
  public addValidationErrors(validationErrors: any[]) {
    let newList = getNewValidationErrorsList(this.state.validationErrors, validationErrors);
    this.setState({ validationErrors: newList });
  }

  public getValidationError(fieldName: string) {
    //Call the validation method from pageUtils
    return getValidationError(this.state.validationErrors, fieldName, "validation-error0");
  }

  //Whenever we update a field, remove any previous error for that field
  //Also reset the lastUpdatedTextbox in state
  public removeValidationError(fieldName: string) {
    let newArray = getFilteredValidationErrors(this.state.validationErrors, fieldName);
    this.setState({ validationErrors: newArray, lastUpdatedTextbox: "" });
  }

  //------------------------------------------------------------------------------------------
  private getActiveId(componentName: string) {
    if (componentName === "OutsideAirInlet") {
      return "OAInlet";
    }
    if (componentName === "SupplyAirOutlet") {
      return "SAOutlet";
    }
    if (componentName === "ExhaustAirOutlet") {
      return "EAOutlet";
    }
    if (componentName === "ReturnAirInlet") {
      return "RAInlet";
    }
    return "";
  }
  private async togglePanel(componentName: string) {
    this.setState({ activeComponent: componentName, activeId: this.getActiveId(componentName) });
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    const { projectId, unitId } = this.props.match.params;
    const result = await callGetEndpoint(`readapi/GetInletOutletSizingValuesByComponent/${componentName}`, projectId, unitId);
    this.setState({
      userInputs: result.uiDataContainer.primaryInputs,
      displayFields: result.uiDataContainer.displayFields,
      dropdownOptions: result.dropdownOptions,
      validationErrors: result.validationErrors,
      individualErrors: result.individualErrors,
      completeData: result.completeData
    });
    this.getDrawingSvg();
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });
  }
  private getPrerequisiteErrors() {
    const errors = this.state.prerequisiteErrorsList;
    if (errors) {
      return errors.map((item: any, index: number) => (
        <div key={index}>
          <div className="opt-err1">{item.source}</div>
          <div className="opt-err2">{item.errorMessage}</div>
        </div>
      ));
    }
    return null;
  }
  public render() {
    if (this.state.showPrerequisiteErrors) {
      return (
        <div className="opt-prereq">
          <div className="opt-prereq-title">Warning</div>
          <div className="opt-prereq-msg">Design calculations cannot be run due to missing/invalid fields:</div>
          <div className="opt-prereq-heading">
            <div>Page</div>
            <div>Field</div>
          </div>
          {this.getPrerequisiteErrors()}
          <div className="opt-spacer"></div>

          <div>Please revisit these page(s) and fix any invalid inputs.</div>
        </div>
      );
    }
    const savedValues = this.state.userInputs;
    const displayFields = this.state.displayFields;
    let activeComponent = this.state.activeComponent;

    if (!savedValues) {
      return null;
    }
    let oaAcfm = savedValues.economizer === "None" ? savedValues.outsideAirAltitudeOnlyAcfmUserValue : savedValues.outsideAirAltitudeOnlyAcfm;
    let eaAcfm = savedValues.economizer === "None" ? savedValues.exhaustAirAltitudeOnlyAcfmUserValue : savedValues.exhaustAirAltitudeOnlyAcfm;

    return (
      <div className="sizing-main">
        <div className="sizing-container">
          <div className="input-heading">Air Inlets and Outlets</div>
          <div className="sizing-accordions">
            {/* ------------------------------ OutsideAirInlet ------------------------------------------------------------------------ */}
            <div className="">
              <AccordionHeader
                title={`Outside Air Inlet ${savedValues.outsideAirInlet}`}
                subtitle=""
                classname="accordion-btn acc-btn-450"
                active={activeComponent == "OutsideAirInlet"}
                locked={false}
                hasError={false}
                togglePanel={() => this.togglePanel("OutsideAirInlet")}
              />

              <div className={`acc-panel-450 ${activeComponent == "OutsideAirInlet" ? "acc-panel-active" : "acc-panel-inactive"}`}>
                <div className="accordion-content acc-indent app-box-shadow">
                  <div className="sizing-spacer"></div>

                  <div className="input-fields">
                    <div className="accordion-label-162">Airflow</div>
                    <div className="accordion-label-90">{formatNumber(oaAcfm, 0)}</div>

                    <div className="accordion-row">
                      <div className="accordion-label-154">Sizing Method</div>
                      <div className="accordion-input">
                        <select
                          className="accordion-select-98"
                          value={savedValues.outsideInletSizingMethod || ""}
                          onChange={(event) => this.saveInputValue("OutsideInletSizingMethod", event.currentTarget.value)}
                        >
                          {getSelectBoxOptions(this.state.dropdownOptions, "outsideAirSizingMethodOptions")}
                        </select>
                        {this.getValidationError("outsideInletSizingMethod")}
                      </div>
                    </div>
                  </div>

                  <div className="input-heading">{savedValues.outsideAirInlet} Size</div>

                  <div className="input-fields">
                    <div className="accordion-row">
                      <div className="accordion-label-inletoutlet">Dimension A</div>

                      {displayFields.outsideInletDimensionsAreReadOnly ? (
                        <div className="accordion-label-90">{savedValues.outsideInletDimensionA}</div>
                      ) : (
                        <div className="accordion-input">
                          <input
                            type="text"
                            className={this.getCSS("outsideInletDimensionA", "accordiontextbox")}
                            value={savedValues.outsideInletDimensionA || ""}
                            onChange={(event) => this.updateTextboxValue("outsideInletDimensionA", event.currentTarget.value)}
                            onBlur={(event) => this.saveTextboxValue("outsideInletDimensionA", event.currentTarget.value)}
                          />
                        </div>
                      )}
                      {this.getValidationError("outsideInletDimensionA")}
                    </div>

                    <div className="accordion-row">
                      <div className="accordion-label-inletoutlet">Dimension B</div>

                      {displayFields.outsideInletDimensionsAreReadOnly ? (
                        <div className="accordion-label-90">{savedValues.outsideInletDimensionB}</div>
                      ) : (
                        <div className="accordion-input">
                          <input
                            type="text"
                            className={this.getCSS("outsideInletDimensionB", "accordiontextbox")}
                            value={savedValues.outsideInletDimensionB || ""}
                            onChange={(event) => this.updateTextboxValue("outsideInletDimensionB", event.currentTarget.value)}
                            onBlur={(event) => this.saveTextboxValue("outsideInletDimensionB", event.currentTarget.value)}
                          />
                        </div>
                      )}
                      {this.getValidationError("outsideInletDimensionB")}
                    </div>

                    <div className="accordion-row">
                      <div className="accordion-label-161">{savedValues.outsideAirInlet === "Louver" ? "Free Area Face Velocity" : "Face Velocity"}</div>

                      {displayFields.outsideInletFaceVelocityIsReadOnly ? (
                        <div className="accordion-input">
                          <div className="accordion-label-90">{formatNumber(savedValues.outsideInletFaceVelocityFpm, 0)}</div>
                        </div>
                      ) : (
                        <div className="accordion-input">
                          <input
                            type="text"
                            className={this.getCSS("outsideInletFaceVelocityFpm", "accordiontextbox")}
                            value={formatNumber(savedValues.outsideInletFaceVelocityFpm, 0) || ""}
                            onChange={(event) => this.updateTextboxValue("outsideInletFaceVelocityFpm", event.currentTarget.value)}
                            onBlur={(event) => this.saveTextboxValue("outsideInletFaceVelocityFpm", event.currentTarget.value)}
                          />
                        </div>
                      )}
                      {this.getValidationError("outsideInletFaceVelocityFpm")}
                    </div>
                  </div>

                  <div className="input-heading">{savedValues.outsideAirInlet} Position</div>

                  <div className="input-fields">
                    {this.state.displayFields.showOutsideAirDimensionC ? (
                      <div className="accordion-row">
                        <div className="accordion-label-inletoutlet">Dimension C</div>

                        {displayFields.outsideInletDimensionsAreReadOnly ? (
                          <div className="accordion-label-90">{savedValues.outsideInletDimensionC}</div>
                        ) : (
                          <div className="accordion-input">
                            <input
                              type="text"
                              className={this.getCSS("outsideInletDimensionC", "accordiontextbox")}
                              value={savedValues.outsideInletDimensionC || ""}
                              onChange={(event) => this.updateTextboxValue("outsideInletDimensionC", event.currentTarget.value)}
                              onBlur={(event) => this.saveTextboxValue("outsideInletDimensionC", event.currentTarget.value)}
                            />
                          </div>
                        )}
                        {this.getValidationError("outsideInletDimensionC")}
                      </div>
                    ) : null}

                    <div className="accordion-row">
                      <div className="accordion-label-inletoutlet">Dimension D</div>

                      {displayFields.outsideInletDimensionsAreReadOnly ? (
                        <div className="accordion-label-90">{savedValues.outsideInletDimensionD}</div>
                      ) : (
                        <div className="accordion-input">
                          <input
                            type="text"
                            className={this.getCSS("outsideInletDimensionD", "accordiontextbox")}
                            value={savedValues.outsideInletDimensionD || ""}
                            onChange={(event) => this.updateTextboxValue("outsideInletDimensionD", event.currentTarget.value)}
                            onBlur={(event) => this.saveTextboxValue("outsideInletDimensionD", event.currentTarget.value)}
                          />
                        </div>
                      )}
                      {this.getValidationError("outsideInletDimensionD")}
                    </div>
                  </div>
                  <div className="accordion-row"></div>
                </div>
              </div>
            </div>

            {/* ------------------------------------- SupplyAirOutlet ----------------------------------------------------------------- */}
            <div className="">
              <AccordionHeader
                title={`Supply Air Outlet ${savedValues.supplyAirOutlet}`}
                subtitle=""
                classname="accordion-btn acc-btn-450"
                active={activeComponent == "SupplyAirOutlet"}
                locked={false}
                hasError={false}
                togglePanel={() => this.togglePanel("SupplyAirOutlet")}
              />

              <div className={`acc-panel-450 ${activeComponent == "SupplyAirOutlet" ? "acc-panel-active" : "acc-panel-inactive"}`}>
                <div className="accordion-content acc-indent app-box-shadow">
                  <div className="sizing-spacer"></div>

                  <div className="input-fields">
                    <div className="accordion-label-162">Airflow</div>
                    <div className="accordion-label-90">{formatNumber(this.state.userInputs.supplyAirAltitudeOnlyAcfm, 0)}</div>

                    <div className="accordion-row">
                      <div className="accordion-label-154">Sizing Method</div>
                      <div className="accordion-input">
                        <select
                          className="accordion-select-98"
                          value={savedValues.supplyOutletSizingMethod || ""}
                          onChange={(event) => this.saveInputValue("supplyOutletSizingMethod", event.currentTarget.value)}
                        >
                          {getSelectBoxOptions(this.state.dropdownOptions, "supplyAirSizingMethodOptions")}
                        </select>
                        {this.getValidationError("supplyOutletSizingMethod")}
                      </div>
                    </div>
                  </div>

                  <div className="input-heading">{savedValues.supplyAirOutlet} Size</div>

                  <div className="input-fields">
                    <div className="accordion-row">
                      <div className="accordion-label-inletoutlet">Dimension A</div>

                      {displayFields.supplyOutletDimensionsAreReadOnly ? (
                        <div className="accordion-label-90">{savedValues.supplyOutletDimensionA}</div>
                      ) : (
                        <div className="accordion-input">
                          <input
                            type="text"
                            className={this.getCSS("supplyOutletDimensionA", "accordiontextbox")}
                            value={savedValues.supplyOutletDimensionA || ""}
                            onChange={(event) => this.updateTextboxValue("supplyOutletDimensionA", event.currentTarget.value)}
                            onBlur={(event) => this.saveTextboxValue("supplyOutletDimensionA", event.currentTarget.value)}
                          />
                        </div>
                      )}
                      {this.getValidationError("supplyOutletDimensionA")}
                    </div>

                    <div className="accordion-row">
                      <div className="accordion-label-inletoutlet">Dimension B</div>

                      {displayFields.supplyOutletDimensionsAreReadOnly ? (
                        <div className="accordion-label-90">{savedValues.supplyOutletDimensionB}</div>
                      ) : (
                        <div className="accordion-input">
                          <input
                            type="text"
                            className={this.getCSS("supplyOutletDimensionB", "accordiontextbox")}
                            value={savedValues.supplyOutletDimensionB || ""}
                            onChange={(event) => this.updateTextboxValue("supplyOutletDimensionB", event.currentTarget.value)}
                            onBlur={(event) => this.saveTextboxValue("supplyOutletDimensionB", event.currentTarget.value)}
                          />
                        </div>
                      )}
                      {this.getValidationError("supplyOutletDimensionB")}
                    </div>

                    <div className="accordion-row">
                      <div className="accordion-label-161">{savedValues.supplyAirOutlet === "Louver" ? "Free Area Face Velocity" : "Face Velocity"}</div>

                      {displayFields.supplyOutletFaceVelocityIsReadOnly ? (
                        <div className="accordion-input">
                          <div className="accordion-label-90">{formatNumber(savedValues.supplyOutletFaceVelocityFpm, 0)}</div>
                        </div>
                      ) : (
                        <div className="accordion-input">
                          <input
                            type="text"
                            className={this.getCSS("supplyOutletFaceVelocityFpm", "accordiontextbox")}
                            value={formatNumber(savedValues.supplyOutletFaceVelocityFpm, 0) || ""}
                            onChange={(event) => this.updateTextboxValue("supplyOutletFaceVelocityFpm", event.currentTarget.value)}
                            onBlur={(event) => this.saveTextboxValue("supplyOutletFaceVelocityFpm", event.currentTarget.value)}
                          />
                        </div>
                      )}
                      {this.getValidationError("supplyOutletFaceVelocityFpm")}
                    </div>
                  </div>

                  <div className="input-heading">{savedValues.supplyAirOutlet} Position</div>

                  <div className="input-fields">
                    <div className="accordion-row">
                      <div className="accordion-label-inletoutlet">Dimension C</div>

                      {displayFields.supplyOutletDimensionsAreReadOnly ? (
                        <div className="accordion-label-90">{savedValues.supplyOutletDimensionC}</div>
                      ) : (
                        <div className="accordion-input">
                          <input
                            type="text"
                            className={this.getCSS("supplyOutletDimensionC", "accordiontextbox")}
                            value={savedValues.supplyOutletDimensionC || ""}
                            onChange={(event) => this.updateTextboxValue("supplyOutletDimensionC", event.currentTarget.value)}
                            onBlur={(event) => this.saveTextboxValue("supplyOutletDimensionC", event.currentTarget.value)}
                          />
                        </div>
                      )}
                      {this.getValidationError("supplyOutletDimensionC")}
                    </div>

                    <div className="accordion-row">
                      <div className="accordion-label-inletoutlet">Dimension D</div>

                      {displayFields.supplyOutletDimensionsAreReadOnly ? (
                        <div className="accordion-label-90">{savedValues.supplyOutletDimensionD}</div>
                      ) : (
                        <div className="accordion-input">
                          <input
                            type="text"
                            className={this.getCSS("supplyOutletDimensionD", "accordiontextbox")}
                            value={savedValues.supplyOutletDimensionD || ""}
                            onChange={(event) => this.updateTextboxValue("supplyOutletDimensionD", event.currentTarget.value)}
                            onBlur={(event) => this.saveTextboxValue("supplyOutletDimensionD", event.currentTarget.value)}
                          />
                        </div>
                      )}
                      {this.getValidationError("supplyOutletDimensionD")}
                    </div>
                  </div>
                  <div className="accordion-row"></div>
                </div>
              </div>
            </div>

            {/* ------------------------------------- ReturnAirInlet ----------------------------------------------------------------- */}

            {this.state.displayFields.showReturnAirInlet ? (
              <div className="">
                <AccordionHeader
                  title={`Return Air Inlet ${savedValues.returnAirInlet}`}
                  subtitle=""
                  classname="accordion-btn acc-btn-450"
                  active={activeComponent == "ReturnAirInlet"}
                  locked={false}
                  hasError={false}
                  togglePanel={() => this.togglePanel("ReturnAirInlet")}
                />

                <div className={`acc-panel-450 ${activeComponent == "ReturnAirInlet" ? "acc-panel-active" : "acc-panel-inactive"}`}>
                  <div className="accordion-content acc-indent app-box-shadow">
                    <div className="sizing-spacer"></div>

                    <div className="input-fields">
                      <div className="accordion-label-162">Airflow</div>
                      <div className="accordion-label-90">{formatNumber(this.state.userInputs.returnAirAltitudeOnlyAcfm, 0)}</div>

                      <div className="accordion-row">
                        <div className="accordion-label-154">Sizing Method</div>
                        <div className="accordion-input">
                          <select
                            className="accordion-select-98"
                            value={savedValues.returnInletSizingMethod || ""}
                            onChange={(event) => this.saveInputValue("returnInletSizingMethod", event.currentTarget.value)}
                          >
                            {getSelectBoxOptions(this.state.dropdownOptions, "returnAirSizingMethodOptions")}
                          </select>
                          {this.getValidationError("returnInletSizingMethod")}
                        </div>
                      </div>
                    </div>

                    <div className="input-heading">{savedValues.returnAirInlet} Size</div>

                    <div className="input-fields">
                      <div className="accordion-row">
                        <div className="accordion-label-inletoutlet">Dimension A</div>

                        {displayFields.returnInletDimensionsAreReadOnly ? (
                          <div className="accordion-label-90">{savedValues.returnInletDimensionA}</div>
                        ) : (
                          <div className="accordion-input">
                            <input
                              type="text"
                              className={this.getCSS("returnInletDimensionA", "accordiontextbox")}
                              value={savedValues.returnInletDimensionA || ""}
                              onChange={(event) => this.updateTextboxValue("returnInletDimensionA", event.currentTarget.value)}
                              onBlur={(event) => this.saveTextboxValue("returnInletDimensionA", event.currentTarget.value)}
                            />
                          </div>
                        )}
                        {this.getValidationError("returnInletDimensionA")}
                      </div>

                      <div className="accordion-row">
                        <div className="accordion-label-inletoutlet">Dimension B</div>

                        {displayFields.returnInletDimensionsAreReadOnly ? (
                          <div className="accordion-label-90">{savedValues.returnInletDimensionB}</div>
                        ) : (
                          <div className="accordion-input">
                            <input
                              type="text"
                              className={this.getCSS("returnInletDimensionB", "accordiontextbox")}
                              value={savedValues.returnInletDimensionB || ""}
                              onChange={(event) => this.updateTextboxValue("returnInletDimensionB", event.currentTarget.value)}
                              onBlur={(event) => this.saveTextboxValue("returnInletDimensionB", event.currentTarget.value)}
                            />
                          </div>
                        )}
                        {this.getValidationError("returnInletDimensionB")}
                      </div>

                      <div className="accordion-row">
                        <div className="accordion-label-155">{savedValues.returnAirInlet === "Louver" ? "Free Area Face Velocity" : "Face Velocity"}</div>

                        {displayFields.returnInletFaceVelocityIsReadOnly ? (
                          <div className="accordion-input">
                            <div className="accordion-label-90">{formatNumber(savedValues.returnInletFaceVelocityFpm, 0)}</div>
                          </div>
                        ) : (
                          <div className="accordion-input">
                            <input
                              type="text"
                              className={this.getCSS("returnInletFaceVelocityFpm", "accordiontextbox")}
                              value={formatNumber(savedValues.returnInletFaceVelocityFpm, 0) || ""}
                              onChange={(event) => this.updateTextboxValue("returnInletFaceVelocityFpm", event.currentTarget.value)}
                              onBlur={(event) => this.saveTextboxValue("returnInletFaceVelocityFpm", event.currentTarget.value)}
                            />
                          </div>
                        )}
                        {this.getValidationError("returnInletFaceVelocityFpm")}
                      </div>
                    </div>

                    <div className="input-heading">{savedValues.returnAirInlet} Position</div>

                    <div className="input-fields">
                      <div className="accordion-row">
                        <div className="accordion-label-inletoutlet">Dimension C</div>

                        {displayFields.returnInletDimensionsAreReadOnly ? (
                          <div className="accordion-label-90">{savedValues.returnInletDimensionC}</div>
                        ) : (
                          <div className="accordion-input">
                            <input
                              type="text"
                              className={this.getCSS("returnInletDimensionC", "accordiontextbox")}
                              value={savedValues.returnInletDimensionC || ""}
                              onChange={(event) => this.updateTextboxValue("returnInletDimensionC", event.currentTarget.value)}
                              onBlur={(event) => this.saveTextboxValue("returnInletDimensionC", event.currentTarget.value)}
                            />
                          </div>
                        )}
                        {this.getValidationError("returnInletDimensionC")}
                      </div>

                      <div className="accordion-row">
                        <div className="accordion-label-inletoutlet">Dimension D</div>

                        {displayFields.returnInletDimensionsAreReadOnly ? (
                          <div className="accordion-label-90">{savedValues.returnInletDimensionD}</div>
                        ) : (
                          <div className="accordion-input">
                            <input
                              type="text"
                              className={this.getCSS("returnInletDimensionD", "accordiontextbox")}
                              value={savedValues.returnInletDimensionD || ""}
                              onChange={(event) => this.updateTextboxValue("returnInletDimensionD", event.currentTarget.value)}
                              onBlur={(event) => this.saveTextboxValue("returnInletDimensionD", event.currentTarget.value)}
                            />
                          </div>
                        )}
                        {this.getValidationError("returnInletDimensionD")}
                      </div>
                    </div>
                    <div className="accordion-row"></div>
                  </div>
                </div>
              </div>
            ) : null}

            {/* ------------------------------------- ExhaustAirOutlet ----------------------------------------------------------------- */}

            {this.state.displayFields.showExhaustAirOutlet ? (
              <div className="">
                <AccordionHeader
                  title={`Exhaust Air Outlet ${savedValues.exhaustAirOutlet}`}
                  subtitle=""
                  classname="accordion-btn acc-btn-450"
                  active={activeComponent == "ExhaustAirOutlet"}
                  locked={false}
                  hasError={false}
                  togglePanel={() => this.togglePanel("ExhaustAirOutlet")}
                />

                <div className={`acc-panel-450 ${activeComponent == "ExhaustAirOutlet" ? "acc-panel-active" : "acc-panel-inactive"}`}>
                  <div className="accordion-content acc-indent app-box-shadow">
                    <div className="sizing-spacer"></div>

                    <div className="input-fields">
                      <div className="accordion-label-162">Airflow</div>
                      <div className="accordion-label-90">{formatNumber(eaAcfm, 0)}</div>

                      <div className="accordion-row">
                        <div className="accordion-label-154">Sizing Method</div>
                        <div className="accordion-input">
                          <select
                            className="accordion-select-98"
                            value={savedValues.exhaustOutletSizingMethod || ""}
                            onChange={(event) => this.saveInputValue("exhaustOutletSizingMethod", event.currentTarget.value)}
                          >
                            {getSelectBoxOptions(this.state.dropdownOptions, "exhaustAirSizingMethodOptions")}
                          </select>
                          {this.getValidationError("exhaustOutletSizingMethod")}
                        </div>
                      </div>
                    </div>

                    <div className="input-heading">{savedValues.exhaustAirOutlet} Size</div>

                    <div className="input-fields">
                      <div className="accordion-row">
                        <div className="accordion-label-inletoutlet">Dimension A</div>
                        {displayFields.exhaustOutletDimensionsAreReadOnly ? (
                          <div className="accordion-label-90">{savedValues.exhaustOutletDimensionA}</div>
                        ) : (
                          <div className="accordion-input">
                            <input
                              type="text"
                              className={this.getCSS("exhaustOutletDimensionA", "accordiontextbox")}
                              value={savedValues.exhaustOutletDimensionA || ""}
                              onChange={(event) => this.updateTextboxValue("exhaustOutletDimensionA", event.currentTarget.value)}
                              onBlur={(event) => this.saveTextboxValue("exhaustOutletDimensionA", event.currentTarget.value)}
                            />
                          </div>
                        )}
                        {this.getValidationError("exhaustOutletDimensionA")}
                      </div>

                      <div className="accordion-row">
                        <div className="accordion-label-inletoutlet">Dimension B</div>
                        {displayFields.exhaustOutletDimensionsAreReadOnly ? (
                          <div className="accordion-label-90">{savedValues.exhaustOutletDimensionB}</div>
                        ) : (
                          <div className="accordion-input">
                            <input
                              type="text"
                              className={this.getCSS("exhaustOutletDimensionB", "accordiontextbox")}
                              value={savedValues.exhaustOutletDimensionB || ""}
                              onChange={(event) => this.updateTextboxValue("exhaustOutletDimensionB", event.currentTarget.value)}
                              onBlur={(event) => this.saveTextboxValue("exhaustOutletDimensionB", event.currentTarget.value)}
                            />
                          </div>
                        )}
                        {this.getValidationError("exhaustOutletDimensionB")}
                      </div>

                      <div className="accordion-row">
                        <div className="accordion-label-155">{savedValues.exhaustAirOutlet === "Louver" ? "Free Area Face Velocity" : "Face Velocity"}</div>
                        {displayFields.exhaustOutletFaceVelocityIsReadOnly ? (
                          <div className="accordion-input">
                            <div className="accordion-label-90">{formatNumber(savedValues.exhaustOutletFaceVelocityFpm, 0)}</div>
                          </div>
                        ) : (
                          <div className="accordion-input">
                            <input
                              type="text"
                              className={this.getCSS("exhaustOutletFaceVelocityFpm", "accordiontextbox")}
                              value={formatNumber(savedValues.exhaustOutletFaceVelocityFpm, 0) || ""}
                              onChange={(event) => this.updateTextboxValue("exhaustOutletFaceVelocityFpm", event.currentTarget.value)}
                              onBlur={(event) => this.saveTextboxValue("exhaustOutletFaceVelocityFpm", event.currentTarget.value)}
                            />
                          </div>
                        )}
                        {this.getValidationError("exhaustOutletFaceVelocityFpm")}
                      </div>
                    </div>

                    <div className="input-heading">{savedValues.exhaustAirOutlet} Position</div>

                    <div className="input-fields">
                      {this.state.displayFields.showExhaustAirDimensionC ? (
                        <div className="accordion-row">
                          <div className="accordion-label-inletoutlet">Dimension C</div>
                          {displayFields.exhaustOutletDimensionsAreReadOnly ? (
                            <div className="accordion-label-90">{savedValues.exhaustOutletDimensionC}</div>
                          ) : (
                            <div className="accordion-input">
                              <input
                                type="text"
                                className={this.getCSS("exhaustOutletDimensionC", "accordiontextbox")}
                                value={savedValues.exhaustOutletDimensionC || ""}
                                onChange={(event) => this.updateTextboxValue("exhaustOutletDimensionC", event.currentTarget.value)}
                                onBlur={(event) => this.saveTextboxValue("exhaustOutletDimensionC", event.currentTarget.value)}
                              />
                            </div>
                          )}
                          {this.getValidationError("exhaustOutletDimensionC")}
                        </div>
                      ) : null}

                      <div className="accordion-row">
                        <div className="accordion-label-inletoutlet">Dimension D</div>
                        {displayFields.exhaustOutletDimensionsAreReadOnly ? (
                          <div className="accordion-label-90">{savedValues.exhaustOutletDimensionD}</div>
                        ) : (
                          <div className="accordion-input">
                            <input
                              type="text"
                              className={this.getCSS("exhaustOutletDimensionD", "accordiontextbox")}
                              value={savedValues.exhaustOutletDimensionD || ""}
                              onChange={(event) => this.updateTextboxValue("exhaustOutletDimensionD", event.currentTarget.value)}
                              onBlur={(event) => this.saveTextboxValue("exhaustOutletDimensionD", event.currentTarget.value)}
                            />
                          </div>
                        )}
                        {this.getValidationError("exhaustOutletDimensionD")}
                      </div>
                    </div>
                    <div className="accordion-row"></div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="sizing-container2">
          <div className="sizing-drawing-title">{`${this.getSketchTitle()}`} Dimensioning Sketch</div>

          <div className="sizing-drawing-grid">
            {this.state.drawingSVG !== "" ? <div dangerouslySetInnerHTML={{ __html: this.state.drawingSVG }} /> : null}
            <div className="sizing-note">{this.getConnectionLocation()}</div>
            {this.getDrawingDescription()}
          </div>
        </div>
      </div>
    );
  }

  getDrawingDescription() {
    //const drawingView = this.props.reduxMenus.sizingAccordion.drawingView.toLowerCase();
    const drawingView = this.getConnectionLocation();
    const isTopView = drawingView.toLowerCase().includes("top");

    return (
      <div className="sizing-note2">
        Note: <span className="sizing-green">C</span> and <span className="sizing-green">D</span> are dimensioned to the casing {isTopView ? "" : "and base"}{" "}
        exterior.
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(UnitSizing);
