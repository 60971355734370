import * as React from "react";
import { connect } from "react-redux";
import { mapDispatchToProps } from "../../redux/actions";
import ModalPopup from "./ModalPopup";
import ProjectModal from "./ProjectModal";
import UnitCopyModal from "./UnitCopyModal";
import ProjectCopyModal from "./ProjectCopyModal";
import FanCurveModal from "./FanCurveModal";
import FanSoundDataModal from "./FanSoundDataModal";
import ContactUsModal from "./ContactUsModal";
import RefrigerationLocationModal from "./RefrigerationLocationModal";
import TransferProjectModal from "./TransferProjectModal";
import { resetReduxModals } from "../../utilities/helpers";
import "../../css/Modals.scss";

// expected props
interface IProps {
  reduxModals: any;
  updateRedux: any;
}
// local state
interface IState {}

//This container covers the whole screen and has the black semi-transparent background.
//modalType: indicates which modal box to display in the middle of the screen.
class ModalContainer extends React.Component<IProps, IState> {
  private getModal() {
    if (this.props.reduxModals.modalType === "ModalPopup") {
      return <ModalPopup />;
    }
    //No longer used. Saved in case we go back to selecting unit type via modal.
    // if (this.props.reduxModals.modalType === 'UnitTypeModal') {
    //   return <UnitTypeModal />;
    // }
    if (this.props.reduxModals.modalType === "ProjectModal") {
      return <ProjectModal />;
    }
    if (this.props.reduxModals.modalType === "UnitCopyModal") {
      return <UnitCopyModal />;
    }
    if (this.props.reduxModals.modalType === "ProjectCopyModal") {
      return <ProjectCopyModal />;
    }
    if (this.props.reduxModals.modalType === "FanCurveModal") {
      return <FanCurveModal />;
    }
    if (this.props.reduxModals.modalType === "FanSoundDataModal") {
      return <FanSoundDataModal />;
    }
    if (this.props.reduxModals.modalType === "ContactUsModal") {
      return <ContactUsModal />;
    }
    if (this.props.reduxModals.modalType === "RefrigerationLocationModal") {
      return <RefrigerationLocationModal />;
    }
    if (this.props.reduxModals.modalType === "TransferProjectModal") {
      return <TransferProjectModal />;
    }
  }

  //modal-click-target is the black background, so the user can click anywhere on screen outside of
  //the modal, and it will close & reset.
  public render() {
    const modalCSS = this.props.reduxModals.showModal ? "modal-container" : "app-display-none";

    return (
      <div className={modalCSS}>
        <div className="modal-content">{this.props.reduxModals.showModal ? this.getModal() : null}</div>
        <div className="modal-click-target" onClick={() => resetReduxModals()}></div>
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxModals: state.reduxModals
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
