import * as React from "react";
import { connect } from "react-redux";
import { Route, match } from "react-router-dom";
import DesignSubMenu from "./Menus/DesignSubMenu";
import ConfigurationInputs from "./CreateDesign/ConfigurationInputs";
//import ConnectionsOptions from './CreateDesign/ConnectionsOptions';
import InletOutlet from "./CreateDesign/InletOutlet"; //
import UnitSelection from "./CreateDesign/UnitSelection";
import TempsSystem from "./CreateDesign/TempsSystem";
//import Performance from "./CreateDesign/Performance";
import OptimizeDesign from "./CreateDesign/OptimizeDesign";
import UnitDrawing from "./FineTuneDesign/UnitDrawing";
import { mapDispatchToProps } from "../redux/actions";
import PoolInputs from "./CreateDesign/PoolInputs";
import { checkIsProEditMode } from "../utilities/specmateProHelpers";

// expected props
interface IProps {
  updateRedux: any;
  match: match<any>;
  history: any;
  reduxMenus: any;
}
// local state
interface IState {}

class UnitDesign extends React.Component<IProps, IState> {
  public state: IState = {};
  componentDidMount() {
    this.props.updateRedux("UPDATE_MENUS", { enableUnitIcons: true });
    this.props.updateRedux("UPDATE_MENUS", { topMenu: { activeItem: 2 } });
  }
  async componentDidUpdate() {
    const { projectId, unitId } = this.props.match.params;
    let isProEditMode = await checkIsProEditMode(projectId, unitId);

    if (isProEditMode) {
      this.props.history.push("/project"); //redirect to project page
    }
  }

  public render() {
    return (
      <div className="app-body-content">
        <DesignSubMenu></DesignSubMenu>
        <Route path="/pid/:projectId/uid/:unitId/design/unittype" component={UnitSelection} />
        {this.props.reduxMenus.showPoolInputs ? <Route path="/pid/:projectId/uid/:unitId/design/pools" component={PoolInputs} /> : null}
        <Route path="/pid/:projectId/uid/:unitId/design/config" component={ConfigurationInputs} />
        <Route path="/pid/:projectId/uid/:unitId/design/options" component={InletOutlet} />
        <Route path="/pid/:projectId/uid/:unitId/design/system" component={TempsSystem} />
        <Route path="/pid/:projectId/uid/:unitId/design/performance" component={OptimizeDesign} />
        <Route path="/pid/:projectId/uid/:unitId/design/drawing" component={UnitDrawing} />
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxMenus: state.reduxMenus
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(UnitDesign);
