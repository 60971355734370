import * as React from "react";
import { connect } from "react-redux";
import { Route, match } from "react-router-dom";
import DrawingSubMenu from "./Menus/DrawingSubMenu";
import UnitDrawing from "./FineTuneDesign/UnitDrawing";
import { mapDispatchToProps } from "../redux/actions";
import { checkIsProEditMode } from "../utilities/specmateProHelpers";
import "../css/UnitSelection.scss";

// expected props
interface IProps {
  updateRedux: any;
  match: match<any>;
  history: any;
}
// local state
interface IState {}

class Drawings extends React.Component<IProps, IState> {
  componentDidMount() {
    this.props.updateRedux("UPDATE_MENUS", { topMenu: { activeItem: 3 } });
  }

  async componentDidUpdate() {
    const { projectId, unitId } = this.props.match.params;
    let isProEditMode = await checkIsProEditMode(projectId, unitId);

    if (isProEditMode) {
      this.props.history.push("/project"); //redirect to project page
    }
  }

  public render() {
    return (
      <div className="app-body-content">
        <DrawingSubMenu></DrawingSubMenu>
        <Route path="/pid/:projectId/uid/:unitId/drawing/drawing" component={UnitDrawing} />
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(Drawings);
