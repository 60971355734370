import * as React from "react";
import { connect } from "react-redux";
import { mapDispatchToProps } from "../../redux/actions";
import { deleteUnitPrompt, openUnitCopyModal, openProUnitPrompt } from "./ProjectUtilities";
import { getNumericId } from "../../api-utilities/formatting";
import "../../css/Project.scss";
import { formatNumber } from "../../utilities/helpers";

// expected props
interface IProps {
  updateRedux: any;
  unit: any;
  projectId: string;
  history: any;
  reduxDisplay: any;
}
// local state
interface IState {}

class Unit extends React.Component<IProps, IState> {
  public state: IState = {};

  public render() {
    let isProEditMode = this.props.unit.isProEditMode;
    let spinnerClass = this.props.reduxDisplay.unitToDelete === this.props.unit.id ? "" : "app-display-none";
    let unitPrice = this.props.unit.unitPrice ? "$" + formatNumber(this.props.unit.unitPrice, 0) : "-";
    let priceAvailable = this.props.unit.isPriceAvailable;
    let rowClass = isProEditMode ? "proj-unit-tr-purple" : "proj-unit-tr";
    let copyClass = isProEditMode ? "proj-unit-td proj-link-white" : "proj-unit-td proj-link";
    let deleteClass = this.props.reduxDisplay.unitToDelete === this.props.unit.id ? "app-display-none" : "proj-unit-td proj-link";

    return (
      <div className={rowClass} onClick={() => this.editUnit(this.props.unit)}>
        {/* The "title" property enables tooltips when user mouses over a long unitTag  */}
        <div title={this.props.unit.unitTag} className="proj-unit-td-name">
          {isProEditMode ? (
            <>
              <div className="proj-lock-icon"></div>
              <div className="proj-lock-spacer"></div>
            </>
          ) : null}

          {/* <div className="proj-unit-tag">{this.props.unit.unitTag}</div> */}

          {this.props.unit.unitTag}
        </div>
        {/* The "title" property enables tooltips when user mouses over a long modelNumber  */}
        <div title={this.props.unit.modelNumber} className="proj-unit-td-model">
          {this.props.unit.modelNumber}
        </div>
        <div className="proj-unit-td-price">{priceAvailable ? unitPrice : "Unavailable"}</div>
        <div className="proj-unit-td-qty">{this.props.unit.unitQuantity}</div>
        <div className={copyClass} onClick={(event) => openUnitCopyModal(event, this.props.unit)}>
          copy
        </div>

        <div className={deleteClass} onClick={(event) => deleteUnitPrompt(event, this.props.unit)}>
          delete
        </div>

        <div className={spinnerClass + " proj-unit-td"}>
          <div className="spinner"></div>
        </div>
      </div>
    );
  }
  private editUnit(unit: any) {
    if (unit.isProEditMode) {
      openProUnitPrompt(this.props.unit);
      return;
    }

    let projectId = getNumericId(this.props.projectId);
    let unitId = getNumericId(this.props.unit.id);
    this.props.history.push("/pid/" + projectId + "/uid/" + unitId + "/design/unittype");
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return { reduxDisplay: state.reduxDisplay };
}
export default connect(mapStateToProps, mapDispatchToProps)(Unit);
