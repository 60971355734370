import * as React from "react";
import { store } from "../../redux/store";
import { match } from "react-router-dom";
import { updateReduxAction } from "../../redux/actions";
import { connect } from "react-redux";
import { Link, withRouter, matchPath } from "react-router-dom"; //withRouter needed for location & history properties (see readMe)
import { mapDispatchToProps } from "../../redux/actions";
import { getPriceAndRecalcLevel, updateUnitPrice } from "../../api-calls/pricingDataService";
import IdentityContext from "../../security/IdentityContext";
import { formatNumber } from "../../api-utilities/formatting";
import { isEmptyArray } from "../../utilities/helpers";
import "../../css/Menus.scss";

// expected props
interface IProps {
  history: any;
  location: any;
  reduxPricing: any;
  updateRedux: any;
  match: match<any>;
}
// local state
interface IState {
  totalPrice: string;
  listPrice: string;
  updateInProgress: boolean;
  hasErrors: boolean;
  errorMessages: string[];
  showErrorModal: boolean;
}

class TopRightPricing extends React.Component<IProps, IState> {
  //Connect to global IdentityContext (https://reactjs.org/docs/context.html)
  static contextType = IdentityContext;
  private readonly identityService = this.context;

  public state: IState = {
    totalPrice: "0",
    listPrice: "0",
    updateInProgress: false,
    hasErrors: false,
    errorMessages: [],
    showErrorModal: false
  };

  async componentDidMount() {
    const { projectId, unitId } = this.props.match.params;
    let priceResponse = await getPriceAndRecalcLevel(projectId, unitId);
    if (priceResponse.success === true){
      let needsUpdate = priceResponse.data.recalcLevel === "None" ? false : true;
      this.props.updateRedux("UPDATE_PRICING", { priceNeedsUpdate: needsUpdate });
      let price = priceResponse.data.unitTotalListPrice !== null ? formatNumber(priceResponse.data.unitTotalListPrice, 0).toString() : "0";
      this.setState({listPrice: price});
      //If there's an overall error message, show that instead
      if (priceResponse.data.hasError) {
        let errorArray: string[] = [priceResponse.data.errorMessage];
        this.setState({ hasErrors: true, errorMessages: errorArray, showErrorModal: true });
        return;
      }
    }
  }
  componentDidUpdate(prevProps: any) {
    if (this.props.reduxPricing.runPricing === true) {
      this.updatePrice();
      this.props.updateRedux("UPDATE_PRICING", { runPricing: false, priceNeedsUpdate: false });
    }
  }
  public render() {
    //  const route = this.props.location.pathname;
    //  const displayPricing = route.includes('/pid/:projectId/uid/:unitId') ? true : false;
    // console.log(route);
    // console.log(route.includes('/pid/:projectId/uid/:unitId'));

    let priceClass = this.props.reduxPricing.priceNeedsUpdate ? "top-pricing pricing-disabled" : "top-pricing";
    let priceBtnClass = this.props.reduxPricing.priceNeedsUpdate ? "top-pricing-btn" : "top-pricing-btn pricing-btn-disabled";

    //  if (displayPricing == false) {
    //    return null;
    //  }

    return (
      <div>
        <div className="top-right-pricing">
          {this.state.hasErrors ? (
            <div className="app-topright-pricing">
              <div className="top-pricing pricing-error" onClick={() => this.togglePriceErrorModal()}>
                Price Unavailable
              </div>
            </div>
          ) : (
            <div className="app-topright-pricing">
              <div className={priceClass}>${this.state.listPrice}</div>
            </div>
          )}

          <div className="app-topright-pricing">
            {this.state.updateInProgress ? (
              <div className="top-pricing-btn pricing-btn-disabled">
                <div className="proj-progress-bar"></div>
                <div className="proj-progress">Updating</div>
              </div>
            ) : (
              <div className={priceBtnClass} onClick={() => this.updatePrice()}>
                Update Price
              </div>
            )}
          </div>
        </div>

        {this.state.showErrorModal ? (
          <div className="top-right-modal">
            <div className="topright-error-close" onClick={() => this.closeErrorModal()}>
              X
            </div>
            <div className="topright-error-heading">Pricing cannot be calculated until errors are resolved</div>
            <div>{this.getErrorMessages()}</div>
          </div>
        ) : null}
      </div>
    );
  }

  private async updatePrice() {
    const { projectId, unitId } = this.props.match.params;
    //If price doesn't need updating, don't execute the call
    if (!this.props.reduxPricing.priceNeedsUpdate) {
      return;
    }

    // const match = matchPath(this.props.history.location.pathname, {
    //   path: '/pid/:projectId/uid/:unitId',
    //   exact: false,
    //   strict: false
    // });

    //if (match) {
    //const { projectId, unitId } = match.params as any;

    this.setState({ updateInProgress: true, hasErrors: false, errorMessages: [], showErrorModal: false });
    store.dispatch(updateReduxAction("UPDATE_DISPLAY", { showLoader: true }));
    let priceResponse = await updateUnitPrice(projectId, unitId);
    store.dispatch(updateReduxAction("UPDATE_DISPLAY", { showLoader: false }));
    //If there's a collection of component-level errors, show those
    if (!isEmptyArray(priceResponse.errorMessages)) {
      this.setState({ updateInProgress: false, hasErrors: true, errorMessages: priceResponse.errorMessages, showErrorModal: true });
      return;
    }
    //If there's an overall error message, show that instead
    if (priceResponse.hasError) {
      let errorArray: string[] = [priceResponse.errorMessage];
      this.setState({ updateInProgress: false, hasErrors: true, errorMessages: errorArray, showErrorModal: true });
      return;
    }
    //If no errors
    this.setState({
      updateInProgress: false,
      hasErrors: false,
      totalPrice: formatNumber(priceResponse.totalPrice, 0).toString(),
      listPrice: formatNumber(priceResponse.listPrice, 0).toString()
    });
    this.props.updateRedux("UPDATE_PRICING", { priceNeedsUpdate: false });
    // }
  }

  private getErrorMessages() {
    let errors = this.state.errorMessages;

    return errors.map((item: any, index: number) => (
      <li key={index} className="topright-error-item">
        {item}
      </li>
    ));
  }
  private togglePriceErrorModal() {
    this.setState({ showErrorModal: !this.state.showErrorModal });
  }
  private closeErrorModal() {
    this.setState({ showErrorModal: false });
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxPricing: state.reduxPricing,
    reduxProjectManagement: state.reduxProjectManagement
  };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopRightPricing));
