import * as React from "react";
import { connect } from "react-redux";
import { mapDispatchToProps } from "../../redux/actions";
import { ACCUArrangement, AirTunnelOrientation } from "../../interfaces/interfaces";
import "../../css/RefrigerationSelectionModal.scss";
// expected props
interface IProps {
  updateRedux: any;
  reduxModals: any;
}
// local state
interface IState {
  accuArrangementSelected: string;
  displayFields: any;
}

class RefrigerationLocationModal extends React.Component<IProps, IState> {
  public state: IState = { accuArrangementSelected: "Automatic", displayFields: null };

  componentDidMount() {
    this.setState({ accuArrangementSelected: this.props.reduxModals.modalData.ACCUArrangement, displayFields: this.props.reduxModals.modalData.displayFields });
  }
  //return the "selected" classname for the currently selected option
  private checkIfSelected(type: string) {
    return this.state.accuArrangementSelected === type ? "refrig-img-selected" : "refrig-img-not-selected";
  }
  private checkIfAutoSelected(type: string) {
    if (this.state.accuArrangementSelected === type) {
      return "refrig-img-auto-container-selected";
    } else {
      return "refrig-img-auto-container";
    }
  }
  private markAsSelected(type: string) {
    this.setState({ accuArrangementSelected: type });
  }

  public render() {
    return (
      <div className="editArrangement">
        <div className="header">Edit Circuit Arrangement and Location</div>
        <div className="refrig-page">
          <div className="refrig-select-options">
            <div className={this.checkIfAutoSelected(ACCUArrangement[ACCUArrangement.Automatic])}>
              <div className={"refrig-img-auto-available "} onClick={() => this.markAsSelected(ACCUArrangement[ACCUArrangement.Automatic])}>
                Automatic
              </div>
            </div>
            <br />
            {this.getExhaustImages()}
            <br />
            {this.getEndImages()}
          </div>
        </div>

        <div className="button-container">
          <div className={"refrig-create-btn"} onClick={() => this.userResponse("ok")}>
            Save
          </div>
          <div className="refrig-cancel-btn" onClick={() => this.userResponse("cancel")}>
            Cancel
          </div>
        </div>
      </div>
    );
  }
  private getExhaustImages() {
    let displayFields = this.props.reduxModals.modalData.displayFields;

    if (displayFields.sideBySideCircuitsOne) {
      return (
        <div className="refrig-img-container">
          <div
            className={"refrig-P1 refrig-img-available " + this.checkIfSelected(ACCUArrangement[ACCUArrangement.SingleCircuitSideBySide_P1])}
            onClick={() => this.markAsSelected(ACCUArrangement[ACCUArrangement.SingleCircuitSideBySide_P1])}
          ></div>
          <div className="refrig-image-text">Exhaust</div>
        </div>
      );
    } else if (displayFields.sideBySideCircuitsTwo) {
      return (
        <>
          <div className="refrig-img-container">
            <div
              className={"refrig-P3 refrig-img-available " + this.checkIfSelected(ACCUArrangement[ACCUArrangement.TwoCircuitSideBySideL_P3])}
              onClick={() => this.markAsSelected(ACCUArrangement[ACCUArrangement.TwoCircuitSideBySideL_P3])}
            ></div>
            <div className="refrig-image-text">Exhaust &#38; End Turned</div>
          </div>
          <div className="refrig-img-container">
            <div
              className={"refrig-S3 refrig-img-available " + this.checkIfSelected(ACCUArrangement[ACCUArrangement.TwoCircuitSideBySideInSeries_S3])}
              onClick={() => this.markAsSelected(ACCUArrangement[ACCUArrangement.TwoCircuitSideBySideInSeries_S3])}
            ></div>
            <div className="refrig-image-text">Exhaust Series</div>
          </div>
        </>
      );
    } else if (displayFields.stackedCircuitsOne) {
      return (
        <>
          <div className="refrig-img-container">
            <div
              className={"refrig-P2 refrig-img-available " + this.checkIfSelected(ACCUArrangement[ACCUArrangement.SingleCircuitOverUnderTopTunnel_P2])}
              onClick={() => this.markAsSelected(ACCUArrangement[ACCUArrangement.SingleCircuitOverUnderTopTunnel_P2])}
            ></div>
            <div className="refrig-image-text">Exhaust Turned</div>
          </div>
          <div className="refrig-img-container">
            <div
              className={"refrig-M1 refrig-img-available " + this.checkIfSelected(ACCUArrangement[ACCUArrangement.SingleCircuitOverUnderExhaust_M1])}
              onClick={() => this.markAsSelected(ACCUArrangement[ACCUArrangement.SingleCircuitOverUnderExhaust_M1])}
            ></div>
            <div className="refrig-image-text">Exhaust</div>
          </div>
        </>
      );
    } else if (displayFields.stackedCircuitsTwo) {
      return (
        <>
          <div className="refrig-img-container">
            <div
              className={"refrig-P4 refrig-img-available " + this.checkIfSelected(ACCUArrangement[ACCUArrangement.TwoCircuitOverUnderParallel_P4])}
              onClick={() => this.markAsSelected(ACCUArrangement[ACCUArrangement.TwoCircuitOverUnderParallel_P4])}
            ></div>
            <div className="refrig-image-text">Exhaust Parallel</div>
          </div>

          <div className="refrig-img-container">
            <div
              className={"refrig-M5 refrig-img-available " + this.checkIfSelected(ACCUArrangement[ACCUArrangement.TwoCircuitOverUnderExhaustSeries_M5])}
              onClick={() => this.markAsSelected(ACCUArrangement[ACCUArrangement.TwoCircuitOverUnderExhaustSeries_M5])}
            ></div>
            <div className="refrig-image-text">Exhaust Series</div>
          </div>
        </>
      );
    } else if (displayFields.sideBySideCircuitsThree) {
      return (
        <>
          <div className="refrig-img-container">
            <div
              className={"refrig-T2 refrig-img-available " + this.checkIfSelected(ACCUArrangement[ACCUArrangement.ThreeCircuitSideBySide_T2])}
              onClick={() => this.markAsSelected(ACCUArrangement[ACCUArrangement.ThreeCircuitSideBySide_T2])}
            ></div>
            <div className="refrig-image-text">Exhaust &#38; End Parallel</div>
          </div>
        </>
      );
    }
    else {
      return null;
    }
  }
  private getEndImages() {
    let displayFields = this.props.reduxModals.modalData.displayFields;
    if (displayFields.sideBySideCircuitsOne) {
      return (
        <>
          <div className="refrig-img-container">
            <div
              className={"refrig-M3 refrig-img-available " + this.checkIfSelected(ACCUArrangement[ACCUArrangement.SingleCircuitSideBySideEndTurned_M3])}
              onClick={() => this.markAsSelected(ACCUArrangement[ACCUArrangement.SingleCircuitSideBySideEndTurned_M3])}
            ></div>
            <div className="refrig-image-text">End Turned</div>
          </div>
          <div className="refrig-img-container">
            <div
              className={"refrig-M4 refrig-img-available " + this.checkIfSelected(ACCUArrangement[ACCUArrangement.SingleCircuitSideBySideEnd_M4])}
              onClick={() => this.markAsSelected(ACCUArrangement[ACCUArrangement.SingleCircuitSideBySideEnd_M4])}
            ></div>
            <div className="refrig-image-text">End</div>
          </div>
        </>
      );
    } else if (displayFields.sideBySideCircuitsTwo) {
      return (
        <div className="refrig-img-container">
          <div
            className={"refrig-M7 refrig-img-available " + this.checkIfSelected(ACCUArrangement[ACCUArrangement.TwoCircuitSideBySideEndParallel_M7])}
            onClick={() => this.markAsSelected(ACCUArrangement[ACCUArrangement.TwoCircuitSideBySideEndParallel_M7])}
          ></div>
          <div className="refrig-image-text">End Parallel</div>
        </div>
      );
    } else if (displayFields.stackedCircuitsOne) {
      return (
        <>
          <div className="refrig-img-container">
            <div
              className={"refrig-S1 refrig-img-available " + this.checkIfSelected(ACCUArrangement[ACCUArrangement.SingleCircuitOverUnderFullHeight_S1])}
              onClick={() => this.markAsSelected(ACCUArrangement[ACCUArrangement.SingleCircuitOverUnderFullHeight_S1])}
            ></div>
            <div className="refrig-image-text">End Turned</div>
          </div>
          <div className="refrig-img-container">
            <div
              className={"refrig-M2 refrig-img-available " + this.checkIfSelected(ACCUArrangement[ACCUArrangement.SingleCircuitOverUnderEnd_M2])}
              onClick={() => this.markAsSelected(ACCUArrangement[ACCUArrangement.SingleCircuitOverUnderEnd_M2])}
            ></div>
            <div className="refrig-image-text">End</div>
          </div>
        </>
      );
    } else if (displayFields.stackedCircuitsTwo) {
      return (
        <>
          <div className="refrig-img-container">
            <div
              className={"refrig-S2 refrig-img-available " + this.checkIfSelected(ACCUArrangement[ACCUArrangement.TwoCircuitOverUnderFullHeightParallel_S2])}
              onClick={() => this.markAsSelected(ACCUArrangement[ACCUArrangement.TwoCircuitOverUnderFullHeightParallel_S2])}
            ></div>
            <div className="refrig-image-text">End Parallel</div>
          </div>
          <div className="refrig-img-container">
            <div
              className={"refrig-M6 refrig-img-available " + this.checkIfSelected(ACCUArrangement[ACCUArrangement.TwoCircuitOverUnderEndSeries_M6])}
              onClick={() => this.markAsSelected(ACCUArrangement[ACCUArrangement.TwoCircuitOverUnderEndSeries_M6])}
            ></div>
            <div className="refrig-image-text">End Series</div>
          </div>
        </>
      );
    } else if (displayFields.singleTunnelCircuitsOne) {
      return (
        <>
          <div className="refrig-img-container">
            <div
              className={"refrig-P5 refrig-img-available " + this.checkIfSelected(ACCUArrangement[ACCUArrangement.SingleCircuitSingleTunnelEnd_P5])}
              onClick={() => this.markAsSelected(ACCUArrangement[ACCUArrangement.SingleCircuitSingleTunnelEnd_P5])}
            ></div>
            <div className="refrig-image-text">End</div>
          </div>
          <div className="refrig-img-container">
            <div
              className={"refrig-M8 refrig-img-available " + this.checkIfSelected(ACCUArrangement[ACCUArrangement.SingleCircuitSingleTunnelEndTurned_M8])}
              onClick={() => this.markAsSelected(ACCUArrangement[ACCUArrangement.SingleCircuitSingleTunnelEndTurned_M8])}
            ></div>
            <div className="refrig-image-text">End Turned</div>
          </div>
        </>
      );
    } else if (displayFields.singleTunnelCircuitsTwo) {
      return (
        <>
          <div className="refrig-img-container">
            <div
              className={"refrig-P6 refrig-img-available " + this.checkIfSelected(ACCUArrangement[ACCUArrangement.TwoCircuitSingleTunnelEndParallel_P6])}
              onClick={() => this.markAsSelected(ACCUArrangement[ACCUArrangement.TwoCircuitSingleTunnelEndParallel_P6])}
            ></div>
            <div className="refrig-image-text">End Parallel</div>
          </div>
          <div className="refrig-img-container">
            <div
              className={"refrig-M9 refrig-img-available " + this.checkIfSelected(ACCUArrangement[ACCUArrangement.TwoCircuitSingleTunnelEndSeries_M9])}
              onClick={() => this.markAsSelected(ACCUArrangement[ACCUArrangement.TwoCircuitSingleTunnelEndSeries_M9])}
            ></div>
            <div className="refrig-image-text">End Series</div>
          </div>
        </>
      );
    } else if (displayFields.singleTunnelCircuitsThree) {
      return (
        <>
          <div className="refrig-img-container">
            <div
              className={"refrig-T1 refrig-img-available " + this.checkIfSelected(ACCUArrangement[ACCUArrangement.ThreeCircuitSingleTunnelT_T1])}
              onClick={() => this.markAsSelected(ACCUArrangement[ACCUArrangement.ThreeCircuitSingleTunnelT_T1])}
            ></div>
            <div className="refrig-image-text">End Parallel &#38; Turned</div>
          </div>
        </>
      );
    } else if (displayFields.singleTunnelCircuitsFour) {
      return (
        <>
          <div className="refrig-img-container">
            <div
              className={"refrig-F1 refrig-img-available " + this.checkIfSelected(ACCUArrangement[ACCUArrangement.FourCircuitSingleTunnelEndParallel_F1])}
              onClick={() => this.markAsSelected(ACCUArrangement[ACCUArrangement.FourCircuitSingleTunnelEndParallel_F1])}
            ></div>
            <div className="refrig-image-text">End Parallel &#38; Series</div>
          </div>
        </>
      );
    } else if (displayFields.sideBySideCircuitsFour) {
      return (
        <>
          <div className="refrig-img-container">
            <div
              className={"refrig-F2 refrig-img-available " + this.checkIfSelected(ACCUArrangement[ACCUArrangement.FourCircuitSideBySide_F2])}
              onClick={() => this.markAsSelected(ACCUArrangement[ACCUArrangement.FourCircuitSideBySide_F2])}
            ></div>
            <div className="refrig-image-text">End Parallel &#38; Series</div>
          </div>
        </>
      );
    } else if (displayFields.sideBySideCircuitsThree) {
      return (
        <>
          <div className="refrig-img-container">
            <div
              className={"refrig-T3 refrig-img-available " + this.checkIfSelected(ACCUArrangement[ACCUArrangement.ThreeCircuitSideBySide_T3])}
              onClick={() => this.markAsSelected(ACCUArrangement[ACCUArrangement.ThreeCircuitSideBySide_T3])}
            ></div>
            <div className="refrig-image-text">End Parallel &#38; Turned</div>
          </div>
        </>
      );
    }
   else if (displayFields.stackedCircuitsFour) {
    return (
      <>
        <div className="refrig-img-container">
          <div
            className={"refrig-F3 refrig-img-available " + this.checkIfSelected(ACCUArrangement[ACCUArrangement.FourCircuitOverUnder_F3])}
            onClick={() => this.markAsSelected(ACCUArrangement[ACCUArrangement.FourCircuitOverUnder_F3])}
          ></div>
          <div className="refrig-image-text">End Parallel &#38; Series</div>
        </div>
      </>
    );
   }
   else if (displayFields.stackedCircuitsThree) {
    return (
      <>
        <div className="refrig-img-container">
          <div
            className={"refrig-T4 refrig-img-available " + this.checkIfSelected(ACCUArrangement[ACCUArrangement.ThreeCircuitOverUnder_T4])}
            onClick={() => this.markAsSelected(ACCUArrangement[ACCUArrangement.ThreeCircuitOverUnder_T4])}
          ></div>
          <div className="refrig-image-text">End Parallel &#38; Turned</div>
        </div>
      </>
    );
    } 
    else {
      return null;
    }
  }

  private userResponse(response: string) {
    let refrigerationLocationResponse = {
      response: response,
      accuArrangementSelected: this.state.accuArrangementSelected
    };
    //This will trigger the componentDidUpdate method on ProjectManagement.tsx
    this.props.updateRedux("UPDATE_MODALS", { showModal: false, userResponse: refrigerationLocationResponse });
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxModals: state.reduxModals
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(RefrigerationLocationModal);
