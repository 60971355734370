import * as React from "react";
import { connect } from "react-redux";
import { Route, match } from "react-router-dom";
import SpecSubMenu from "./Menus/SpecSubMenu";
import Controls from "./SelectOptions/Controls";
import Electrical from "./SelectOptions/Electrical";
import Construction from "./SelectOptions/Construction";
import Warranties from "./SelectOptions/Warranties";
import UnitCabinet from "./SelectOptions/UnitCabinet";
import { mapDispatchToProps } from "../redux/actions";
import { checkIsProEditMode } from "../utilities/specmateProHelpers";
import "../css/UnitSelection.scss";
// expected props
interface IProps {
  updateRedux: any;
  match: match<any>;
  history: any;
}
// local state
interface IState {}

class SpecItems extends React.Component<IProps, IState> {
  componentDidMount() {
    this.props.updateRedux("UPDATE_MENUS", { topMenu: { activeItem: 5 } });
  }

  async componentDidUpdate() {
    const { projectId, unitId } = this.props.match.params;
    let isProEditMode = await checkIsProEditMode(projectId, unitId);

    if (isProEditMode) {
      this.props.history.push("/project"); //redirect to project page
    }
  }

  public render() {
    return (
      <div className="app-body-content">
        <SpecSubMenu />
        <Route path="/pid/:projectId/uid/:unitId/spec/controls" component={Controls} />
        <Route path="/pid/:projectId/uid/:unitId/spec/electrical" component={Electrical} />
        <Route path="/pid/:projectId/uid/:unitId/spec/construction" component={Construction} />
        <Route path="/pid/:projectId/uid/:unitId/spec/cabinet" component={UnitCabinet} />
        <Route path="/pid/:projectId/uid/:unitId/spec/warranties" component={Warranties} />
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(SpecItems);
