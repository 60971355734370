import * as React from "react";
import { connect } from "react-redux";
import { Route, match, Link } from "react-router-dom";
import { mapDispatchToProps } from "../../redux/actions";
import { callGetEndpoint, callGetEndpoint2, callPostEndpoint } from "../../api-calls/readApiService";
import { addNotification, removeNotification } from "../../api-utilities/notification-tools";
import { isEmptyArray, sqlToJsDateTime, isNullOrEmpty } from "../../utilities/helpers";

// expected props
interface IProps {
  updateRedux: any;
  match: match<any>;
  reduxMenus: any;
}
// local state
interface IState {
  revit: boolean;
  units: any;
  dataRows: any[];
  unitSelectAll: boolean;
}

class CadOutputs extends React.Component<IProps, IState> {
  public state: IState = {
    revit: true,
    units: null,
    dataRows: [],
    unitSelectAll: false
  };

  async componentDidMount() {
    this.getUnitList();
    this.getCadFiles();
  }

  private async getUnitList() {
    const { projectId, unitId } = this.props.match.params;
    const result = await callGetEndpoint2("dataapi/getUnits", projectId);
    if (result.hasError) {
      const notification = {
        id: "cadUnitsError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">Get Unit List Error {result.errorMessage}</div>
      };
      addNotification(notification);
    }

    if (result.unitList) {
      this.setState({
        units: result.unitList
      });
    }
  }

  private async getCadFiles() {
    const { projectId, unitId } = this.props.match.params;
    const result = await callGetEndpoint("dataapi/getCadFilesForProject", projectId, unitId);

    if (result.hasError) {
      const notification = {
        id: "cadFilesError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">Get CAD Files Error {result.errorMessage}</div>
      };
      addNotification(notification);
    }

    if (result.cadFileProcessingDTOs) {
      this.setState({
        dataRows: result.cadFileProcessingDTOs
      });
    }
  }

  private buildUnitCheckboxes() {
    if (!this.state.units) {
      return null;
    }
    return this.state.units.map((item: any, index: number) => (
      <div key={index}>
        <div className="app-outputs-checkbox app-outputs-units">
          {" "}
          {item.unitTag + (item.isOversized ? " (Oversized)" : item.isProEditMode ? "(Custom Build)" : "")}
        </div>
        <input
          disabled={item.isOversized || item.unitTag === null || item.isProEditMode}
          type="checkbox"
          name="unit"
          value={item.unitTag != null ? item.unitTag : ""}
          checked={item.checked ? true : false}
          onChange={(event) => this.updateUnitSelected(item.id, event.currentTarget.checked)}
        />
      </div>
    ));
  }
  //This is for use with checkboxes.
  private updateUnitSelected(unitId: any, checked: boolean) {
    let units = [...this.state.units];
    let unit = units.find((item: any) => item.id === unitId);
    unit.checked = checked;
    this.setState({ units: units });
  }
  private SelectAllUnits() {
    if (this.state.unitSelectAll) {
      this.ClearAllUnitCheckboxes();
      return;
    } else {
      let units = [...this.state.units];
      units.forEach((unit) => {
        unit.checked = true;
      });

      this.setState({ units: units, unitSelectAll: true });
    }
  }
  private ClearAllUnitCheckboxes() {
    let units = [...this.state.units];
    units.forEach((unit) => {
      unit.checked = false;
    });

    this.setState({ units: units, unitSelectAll: false });
  }
  private async SubmitProcessingRequest() {
    const { projectId, unitId } = this.props.match.params;

    let unitIds: any = [];
    let unitTags: any = [];
    this.state.units.forEach((unit: any) => {
      if (unit.checked == true) {
        unitIds.push(unit.id);
        unitTags.push(unit.unitTag);
      }
    });

    if (isEmptyArray(unitIds)) {
      return;
    }

    const processingRequest = {
      ProjectId: `proj-${projectId}`,
      UnitIds: unitIds,
      UnitTags: unitTags
    };

    //IMPORTANT: Clear out the current selections, and gray out the submit button.
    //This is to prevent the user from doubleClicking or clicking twice, adding multiple records.
    this.ClearAllUnitCheckboxes();
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: true });
    let result = await callPostEndpoint("dataapi/generateRevitFiles", processingRequest);
    this.props.updateRedux("UPDATE_DISPLAY", { showLoader: false });

    if (result.success) {
      if (result.data.hasError) {
        const notification = {
          id: "outputsError",
          notificationType: "error",
          title: "Error",
          content: (
            <div className="nf-div">
              SubmitProcessingRequest {result.data.errorMessage !== null && result.data.errorMessage !== ""} ? {result.data.errorMessage} :{" "}
              {result.data.individualErrors}
            </div>
          )
        };
        addNotification(notification);
      } else {
        this.getCadFiles();
      }
    }

    //this.ClearAllUnitCheckboxes();
  }

  //========================================= Methods for PDF Documents Table ============================================
  //======================================================================================================================

  //Build table rows from the project list in state
  private getPdfRows() {
    const list = this.state.dataRows;

    if (!isEmptyArray(list)) {
      return list.map((item: any, index: number) => (
        <div key={index} className={this.getRowClassName(item.processingStatusName)}>
          <div className="proj-td">{this.getPdfStatus(item.processingStatusName)}</div>
          {/* <div className="proj-td outputs-table-column">{item.projectId}</div> */}
          <div className="proj-td outputs-table-column-overflow">{item.unitIdListString}</div>
          <div className="proj-td outputs-table-column-overflow">{item.unitTagListString}</div>
          <div className="proj-td">{item.fileType}</div>
          <div className="proj-td">{sqlToJsDateTime(item.createDate)}</div>
          <div className="outputs-download-td">{this.getPdfLink(item)}</div>
        </div>
      ));
    } else {
      return <div className="proj-no-results">No Results</div>;
    }
  }

  private getRowClassName(statusName: string) {
    if (statusName == "Completed") {
      return "proj-tr";
    }
    if (statusName == "Failed") {
      return "proj-tr";
    }
    return "proj-tr-running";
  }
  private getPdfLink(item: any) {
    if (!isNullOrEmpty(item.errorMessage)) {
      return "error";
    }

    if (item.processingStatusName == "Completed") {
      let url = item.cadFileUrl;
      return (
        <Link to={{ pathname: url }} target="_blank">
          <div className="proj-zip-icon-sm"></div>
        </Link>
      );
    }
    return null;
  }

  private getPdfStatus(statusName: string) {
    if (statusName == "Completed") {
      return <div>{statusName}</div>;
    }
    if (statusName == "Failed") {
      return <div className="proj-status-failed">{statusName}</div>;
    }
    //For all statuses aside from Completed and Failed, show the progress bar
    return (
      <div>
        <div className="proj-progress-bar"></div>
        <div className="proj-progress">{statusName}</div>
      </div>
    );
  }

  public render() {
    //let showTemplateChoice = !this.state.salesGuidSpecification ? 'app-display-none' : '';
    // let Disabled = this.DisableGenerateButton() ? "app-outputs-generate-disable" : "";
    let disabled = false;
    // let docSelectText = this.state.docSelectAll ? "Clear All" : "Select All";
    let unitSelectText = this.state.unitSelectAll ? "Clear All" : "Select All";
    return (
      <div className="app-outputs">
        {/* <div className="app-outputs-title">Generate Output Documents</div> */}

        <div className="app-output-container">
          {/*================================= Left Column ================================ */}
          <div className="app-outputs-left">
            <div className="app-outputs-heading">Available Documents</div>
            <div className="app-clear"></div>
            {/* <div className="app-outputs-subheading">Available Documents</div> */}
            {/* <a className="linkAsButton" href="#" onClick={() => this.SelectAllDocuments()}>
              {docSelectText}
            </a> */}

            <div className="app-outputs-checkbox app-outputs-units">Revit</div>
            <input type="checkbox" checked={this.state.revit} onChange={(event) => this.setState({ revit: event.currentTarget.checked })} />

            <div className="app-outputs-spacer"></div>
            <div className="app-outputs-heading">Unit Selection</div>
            <div className="app-clear"></div>

            <div className="app-outputs-subheading">Generate Files For</div>
            <a className="linkAsButton" href="#" onClick={() => this.SelectAllUnits()}>
              {unitSelectText}
            </a>
            {this.buildUnitCheckboxes()}

            <div className={"app-outputs-generate-btn " + disabled}>
              <div onClick={() => this.SubmitProcessingRequest()}>Generate Files</div>
            </div>
          </div>

          {/*====================================== Right Column ================================ */}
          <div className="app-outputs-right">
            <div>
              <div className="app-outputs-heading" onClick={() => this.getCadFiles()}>
                CAD Files
              </div>

              <div className="app-outputs-spacer2"></div>

              <div className="outputs-table1">
                <div className="proj-tr">
                  <div className="proj-th">Status</div>
                  {/* <div className="proj-th outputs-table-column">ProjectId</div> */}
                  <div className="proj-th">UnitIds</div>
                  <div className="proj-th">Unit Tags</div>
                  <div className="proj-th outputs-table-doc-type-column">File Type</div>
                  <div className="proj-th">Created</div>
                  <div className="proj-th outputs-table-download-column">Download</div>
                </div>

                {this.getPdfRows()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {
    reduxMenus: state.reduxMenus
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CadOutputs);
