import * as React from "react";
import { store } from "../redux/store";
import { updateReduxAction } from "../redux/actions";
import { runPerformanceCalcs } from "../api-calls/readApiService";
import { addNotification, formatIndividualErrors } from "../api-utilities/notification-tools";

export async function updateUnitDesign(projectId: number, unitId: number) {
  let enableReduxUpdate = true;
  let perfResponse = await runPerformanceCalcs(projectId, unitId);
  //----------Display error messages------------
  if (perfResponse.hasCriticalError) {
    const notification = {
      id: "performanceCriticalError",
      notificationType: "error",
      title: "Error",
      content: <div className="nf-div">Performance calculations failed. {perfResponse.criticalError}</div>
    };
    addNotification(notification);
    enableReduxUpdate = false; //In the case of a critical error, all components will come back null, so don't update the page or it will be blank.
  }
  if (perfResponse.hasUserCasingLimitError) {
    const notification = {
      id: "userCasingHeightWidthError",
      notificationType: "warning",
      title: "Warning",
      content: <div className="nf-div">{perfResponse.userCasingLimitMessage}</div>
    };
    addNotification(notification);
  }
  if (perfResponse.hasCasingLimitError) {
    const notification = {
      id: "casingHeightWidthError",
      notificationType: "warning",
      title: "Warning",
      content: <div className="nf-div">{perfResponse.casingErrorLimitMessage}</div>
    };
    addNotification(notification);
  }
  if (perfResponse.hasUserCasingEscortError) {
    const notification = {
      id: "casingEscortError",
      notificationType: "warning",
      title: "Warning",
      content: <div className="nf-div">{perfResponse.userCasingEscortMessage}</div>
    };
    addNotification(notification);
  }
  if (perfResponse.hasError) {
    const notification = {
      id: "performanceError",
      notificationType: "error",
      title: "Error",
      content: <div className="nf-div">Performance calculations failed. {perfResponse.errorMessage}</div>
    };
    addNotification(notification);
  }
  if (perfResponse.hasSecondaryError) {
    const notification = {
      id: "performanceSecondaryError",
      notificationType: "error",
      title: "Error",
      content: <div className="nf-div">{formatIndividualErrors(perfResponse.secondaryErrorsList)}</div>
    };
    addNotification(notification);
  }
  if(perfResponse.warningMessages  && perfResponse.warningMessages.length > 0 ){
    perfResponse.warningMessages.forEach((element:any, index: number) => {
      const notification = {
        id: "WarningMessages"+ index,
        notificationType: "warning",
        title: "Warning",
        content: <div className="nf-div">{element}</div>
      };
      addNotification(notification);
    });
  }


  if (enableReduxUpdate) {
    store.dispatch(updateReduxAction("UPDATE_COMPONENT_PERFORMANCE", perfResponse));
  }

  return perfResponse;
}
