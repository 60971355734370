import * as React from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../redux/actions';
// import { IdentityService } from '../../security/IdentityService';
import IdentityContext from '../../security/IdentityContext';
import '../../css/Login.scss';
import { saveUserIdentity } from '../../api-calls/projectDataService';

// expected props
interface IProps {
  updateRedux: any;
  history: any;
}
// local state
interface IState {
  success: boolean;
}

class IdentityLogin extends React.Component<IProps, IState> {
  public state: IState = {
    success: true
  };

  //Connect to global IdentityContext (https://reactjs.org/docs/context.html)
  static contextType = IdentityContext;
  private readonly identityService = this.context;

  async componentDidMount() {
    this.props.updateRedux('UPDATE_DISPLAY', { showLoader: true });
    const user = await this.identityService.getUser();

    if (user == null) {
      const response = await this.identityService.completeLogin();
      if (response != null) {
        const user = await this.identityService.getUser();
        //Save user's profile info in sql.
        this.saveUserIdentity(user);
        
        this.props.updateRedux('UPDATE_DISPLAY', { showLoader: false });
        this.props.history.push('/project'); //redirect to project page after successful login
        
      } else {
        this.setState({ success: false }); //show error if login unsuccessful
        this.props.updateRedux('UPDATE_DISPLAY', { showLoader: false });
      }
    } else {
      this.props.updateRedux('UPDATE_DISPLAY', { showLoader: false });
      this.props.history.push('/project'); //user already logged in. redirect to project page
    }
    
  }
  saveUserIdentity(user: any){
    const userIdentityRequest = {
      LoginId: user.profile.sub,
      FirstName: user.profile.FirstName,
      LastName: user.profile.LastName,
      Email: user.profile.Email,
      ProfileData: user.profile.profiledata
    };
    
    let response = saveUserIdentity(userIdentityRequest);
  }
  public render() {
    return (
      <div className="login-inputs">
        {this.state.success === false ? (
          <div>
            <div>Login unsuccessful</div>
            <br />
            <br />
            <button onClick={() => this.identityService.login()}>Try again</button>
          </div>
        ) : null}
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(IdentityLogin);
