//This file contains calls to the System Data Service API
import { displayError, displayErrorString} from '../api-utilities/api-calls';
import {getAuthToken} from './projectDataService';
import { IdentityService } from '../security/IdentityService';

export async function getAppVersionNumber() {
  const apibase = process.env.REACT_APP_CLIENT_API;
  const token = await getAuthToken();

  //if(token != ''){
    try {
      const result = await fetch(apibase + '/dataapi/getLowryProgramVersion', {
        method: 'get',
        headers: { Authorization: `Bearer ${token}` }
      });

      if (result.status === 200) {
        const data = await result.json();
        if (data.hasError){
          displayErrorString(data.errorMessage, "getLowryProgramVersion");
        }
        return data;
      }
      else{
        //Sometimes this call fails because the auth token is invalid but it doesn't log the user out.
        //Auto-logout in that case.
        //const identityService = new IdentityService();
        //const user = await identityService.logout();
        displayErrorString("Error communicating with server.  Try logging out and back in.", "getLowryProgramVersion");
      }
  }
  catch{
    displayErrorString("Unable to retrieve data from server", "getLowryProgramVersion");
    return { hasError: true};
  }
  // }
  // else{
  //   return {hasError:true, errorMessage: "Request failed: Invalid access_token."};
  // }
}