import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router-dom";
import { store } from "../../redux/store";
import { updateReduxAction } from "../../redux/actions";
import { mapDispatchToProps } from "../../redux/actions";
import { addNotification, removeNotification } from "../../api-utilities/notification-tools";
import {
  getAllPoolValues,
  updateInputField,
  addIndividualPool,
  deleteIndividualPool,
  runPoolCalculations,
  runPoolSpaceValidations
} from "../../api-calls/readApiService";
import {
  getValidationError,
  getChecked,
  getSelectBoxOptions,
  getInputCSS,
  checkForErrors,
  getFilteredValidationErrors,
  getNewDropdownList,
  getNewValidationErrorsList
} from "../../utilities/pageUtils";
import "../../css/PoolInputs.scss";
import { formatNumber, isEmptyArray, isNullOrEmpty } from "../../utilities/helpers";
import SinglePoolInput from "./SinglePoolInput";
import { getTooltipContent } from "./PoolUtilities";

// expected props
interface IProps {
  match: match<any>;
}
// local state
interface IState {
  poolSpaceInputs: any;
  poolOneInputs: any;
  poolTwoInputs: any;
  poolThreeInputs: any;
  poolFourInputs: any;
  poolFiveInputs: any;
  poolSpaceDisplayFields: any;
  poolsDisplayFields: any;
  dropdownOptions: any[];
  validationErrors: any[];
  poolValidationErrors: any[];
  poolPrerequisiteErrors: any[];
  lastUpdatedTextbox: string;
  showPrerequisiteErrors: boolean;
  ShowDayDewPointToolTip: boolean;
  ShowPoolApplicationTypeToolTip: boolean;
  ShowPoolContructionTypeToolTip: boolean;
  ShowSpaceVolumeToolTip: boolean;
  ShowAirChangesPerHourToolTip: boolean;
  ShowDeckAreaToolTip: boolean;
  ShowWinterDesignDayDryBulbToolTip: boolean;
  ShowEvapMeanCoincidentDryBulbToolTip: boolean;
  ShowEvapWetBulbToolTip: boolean;
  ShowDesignDayDryBulbToolTip: boolean;
  ShowDryBulbMeanCoincidentWetBulbToolTip: boolean;
  ShowSummerSpaceLoadsToolTip: boolean;
  ShowWinterSpaceLoadsToolTip: boolean;
  ShowPurgeModeToolTip: boolean;
  ShowSeatsQuantityToolTip: boolean;
  ShowSeatingAreaToolTip: boolean;
  ShowSeatingIncludedInPoolDeckAreaToolTip: boolean;
  ShowPoolSpaceAirTemperatureToolTip: boolean;
  ShowSummerIndoorRelativeHumidityToolTip: boolean;
  ShowWinterIndoorRelativeHumidityToolTip: boolean;
}

class PoolInputs extends React.Component<IProps, IState> {
  public state: IState = {
    poolSpaceInputs: null,
    poolOneInputs: null,
    poolTwoInputs: null,
    poolThreeInputs: null,
    poolFourInputs: null,
    poolFiveInputs: null,
    poolSpaceDisplayFields: null,
    poolsDisplayFields: null,
    dropdownOptions: [],
    validationErrors: [],
    poolValidationErrors: [],
    lastUpdatedTextbox: "",
    poolPrerequisiteErrors: [],
    showPrerequisiteErrors: false,
    ShowDayDewPointToolTip: false,
    ShowPoolApplicationTypeToolTip: false,
    ShowPoolContructionTypeToolTip: false,
    ShowSpaceVolumeToolTip: false,
    ShowAirChangesPerHourToolTip: false,
    ShowDeckAreaToolTip: false,
    ShowWinterDesignDayDryBulbToolTip: false,
    ShowEvapMeanCoincidentDryBulbToolTip: false,
    ShowEvapWetBulbToolTip: false,
    ShowDesignDayDryBulbToolTip: false,
    ShowDryBulbMeanCoincidentWetBulbToolTip: false,
    ShowSummerSpaceLoadsToolTip: false,
    ShowWinterSpaceLoadsToolTip: false,
    ShowPurgeModeToolTip: false,
    ShowSeatsQuantityToolTip: false,
    ShowSeatingAreaToolTip: false,
    ShowSeatingIncludedInPoolDeckAreaToolTip: false,
    ShowPoolSpaceAirTemperatureToolTip: false,
    ShowSummerIndoorRelativeHumidityToolTip: false,
    ShowWinterIndoorRelativeHumidityToolTip: false
  };

  async componentDidMount() {
    const { projectId, unitId } = this.props.match.params;
    const result = await getAllPoolValues(projectId, unitId);

    if (result.hasError) {
      const notification = {
        id: "configInputsError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">getAllPoolValues {result.errorMessage}</div>
      };
      addNotification(notification);
    }
    let data = result.uiDataContainer;
    console.log(data);
    this.setState({
      poolSpaceInputs: data.poolSpaceInputs, //All inputs on pool page except individual pools.
      poolOneInputs: isNullOrEmpty(data.poolOneInputs) ? "" : data.poolOneInputs, // Pools 1 through 5.
      poolTwoInputs: isNullOrEmpty(data.poolTwoInputs) ? "" : data.poolTwoInputs,
      poolThreeInputs: isNullOrEmpty(data.poolThreeInputs) ? "" : data.poolThreeInputs,
      poolFourInputs: isNullOrEmpty(data.poolFourInputs) ? "" : data.poolFourInputs,
      poolFiveInputs: isNullOrEmpty(data.poolFiveInputs) ? "" : data.poolFiveInputs,
      poolSpaceDisplayFields: data.poolSpaceDisplayFields, //Display fields for all inputs except indivual pools.
      poolsDisplayFields: data.poolsDisplayFields, //Display fields for individual pools.
      dropdownOptions: result.dropdownOptions,
      validationErrors: result.validationErrors,
      poolValidationErrors: result.poolValidationErrors
    });
  }

  //This function gets called on every keystroke in a textbox.
  public async updatePoolSpaceTextboxValue(fieldName: string, newValue: string) {
    const newState = Object.assign({}, this.state.poolSpaceInputs, { [fieldName]: newValue });
    this.setState({ poolSpaceInputs: newState, lastUpdatedTextbox: fieldName });
  }
  //This gets called by textbox onBlur events
  public async saveTextboxValue(fieldName: string, newValue: string) {
    //If field value hasn't changed, don't call the server
    if (this.state.lastUpdatedTextbox !== fieldName) {
      return;
    }
    this.saveInputValue(fieldName, newValue);
  }

  //Save new value to redis and DB
  public async saveInputValue(fieldName: string, newValue: string) {
    const { projectId, unitId } = this.props.match.params;

    this.removeValidationError(fieldName);
    if (fieldName === "seatingArea" || fieldName === "seatingIncludedInPoolDeckArea") {
      this.removeValidationError("deckArea");
    }
    let result = await updateInputField("updatePoolSpaceInputs", projectId, unitId, fieldName, newValue, "PoolSpaceInputs");

    if (result.success) {
      //If there are any errors in the response, add them to state
      if (result.data.validationErrors != null) {
        this.addValidationErrors(result.data.validationErrors);
      }

      //If there are any dropdownLists present in the response, then update those in state
      if (result.data.dropdownOptions != null) {
        this.updateDropdowns(result.data.dropdownOptions);
      }
      //If uiDataContainer was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        this.setState({
          poolSpaceInputs: result.data.uiDataContainer.poolSpaceInputs,
          poolSpaceDisplayFields: result.data.uiDataContainer.poolSpaceDisplayFields
        });
      }
    }
  }
  //Check for errors on this textbox/selectbox, and return the appropriate CSS className
  public getCSS(fieldName: string, type: string) {
    return getInputCSS(this.state.validationErrors, fieldName, type);
  }

  //Update dropdowns with new options from the server
  public updateDropdowns(newDropdownOptions: any) {
    let newList = getNewDropdownList(this.state.dropdownOptions, newDropdownOptions);
    this.setState({ dropdownOptions: newList });
  }

  //Add any validation errors we got from the server to state
  public addValidationErrors(validationErrors: any[]) {
    let newList = getNewValidationErrorsList(this.state.validationErrors, validationErrors);
    this.setState({ validationErrors: newList });
  }

  public getValidationError(fieldName: string, className: string = "validation-pool") {
    //Call the validation method from pageUtils
    return getValidationError(this.state.validationErrors, fieldName, className);
  }

  //Whenever we update a field, remove any previous error for that field
  //Also reset the lastUpdatedTextbox in state
  public removeValidationError(fieldName: string) {
    let newArray = getFilteredValidationErrors(this.state.validationErrors, fieldName);
    this.setState({ validationErrors: newArray, lastUpdatedTextbox: "" });
  }
  //Add an individual pool.
  private async addPool() {
    const { projectId, unitId } = this.props.match.params;

    let result = await addIndividualPool(projectId, unitId);

    if (result.success) {
      //If there are any errors in the response, add them to state
      if (result.data.validationErrors != null) {
        this.addValidationErrors(result.data.validationErrors);
      }
      //If uiDataContainer was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        this.setState({
          poolOneInputs: result.data.uiDataContainer.poolOneInputs,
          poolTwoInputs: result.data.uiDataContainer.poolTwoInputs,
          poolThreeInputs: result.data.uiDataContainer.poolThreeInputs,
          poolFourInputs: result.data.uiDataContainer.poolFourInputs,
          poolFiveInputs: result.data.uiDataContainer.poolFiveInputs,
          poolsDisplayFields: result.data.uiDataContainer.poolsDisplayFields
        });
      }
    }
  }
  // public runPoolSpaceValidations = async () => {
  //   let context = this;
  //   const { projectId, unitId } = context.props.match.params;
  //   let result = await runPoolSpaceValidations(projectId, unitId);
  //   if (result.hasError) {
  //     const notification = {
  //       id: "configInputsError",
  //       notificationType: "error",
  //       title: "Error",
  //       content: <div className="nf-div">runPoolSpaceValidations {result.errorMessage}</div>
  //     };
  //     addNotification(notification);
  //   }
  //   context.setState({
  //     validationErrors: result.validations
  //   });
  // }
  public deletePool = async (poolIndex: number) => {
    let context = this;
    const { projectId, unitId } = context.props.match.params;
    let result = await deleteIndividualPool(projectId, unitId, poolIndex);

    if (result.success) {
      //If there are any errors in the response, add them to state
      if (result.data.validationErrors != null) {
        context.addValidationErrors(result.data.validationErrors);
      }
      //If uiDataContainer was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        context.setState({
          poolOneInputs: result.data.uiDataContainer.poolOneInputs,
          poolTwoInputs: result.data.uiDataContainer.poolTwoInputs,
          poolThreeInputs: result.data.uiDataContainer.poolThreeInputs,
          poolFourInputs: result.data.uiDataContainer.poolFourInputs,
          poolFiveInputs: result.data.uiDataContainer.poolFiveInputs,
          poolsDisplayFields: result.data.uiDataContainer.poolsDisplayFields
        });
      }
    }
  };
  private async calculatePoolValues() {
    const { projectId, unitId } = this.props.match.params;

    if (this.state.poolSpaceInputs.poolApplicationType === "OneHundredPercentOANoCooling") {
      const notification = {
        id: "poolApplicationType",
        notificationType: "warning",
        title: "warning",
        content: (
          <div className="nf-div">
            100% OA/No Cooling pool application selected. Summer indoor RH% reflects a Dry Bulb Design Day and will rise higher when OA dew point is greater
            than the dew point at this condition and will be uncontrollable. Space temp will be higher than shown and uncontrollable when OA Dry Bulb is greater
            than design space temp.
          </div>
        )
      };
      addNotification(notification);
    }
    //SpaceHeight validation is done after pool calculations.  Need to clear this error each time
    //pool calculations are run.
    this.removeValidationError("SpaceHeight");
    let result = await runPoolCalculations(projectId, unitId);

    if (result.data.hasError) {
      const notification = {
        id: "configInputsError",
        notificationType: "error",
        title: "Error",
        content: <div className="nf-div">runPoolCalculations {result.data.errorMessage}</div>
      };
      addNotification(notification);
    }

    if (result.success) {
      //If there are any errors in the response, add them to state
      if (result.data.validationErrors != null) {
        this.addValidationErrors(result.data.validationErrors);
      }

      //If uiDataContainer was updated with new values, then update it in state
      if (result.data.uiDataContainer != null) {
        this.setState({
          poolSpaceInputs: result.data.uiDataContainer.poolSpaceInputs
        });
      }
      //Display prerequisite errors if there are any
      if (!isEmptyArray(result.data.poolPrerequisiteErrors)) {
        this.setState({ showPrerequisiteErrors: true, poolPrerequisiteErrors: result.data.poolPrerequisiteErrors });
      } else {
        this.setState({ showPrerequisiteErrors: false, poolPrerequisiteErrors: result.data.poolPrerequisiteErrors });
      }
    }
  }
  private getPrerequisiteErrors() {
    const errors = this.state.poolPrerequisiteErrors;
    if (errors) {
      return errors.map((item: any, index: number) => (
        <div key={index}>
          <div className="pool-err">{item.errorMessage}</div>
        </div>
      ));
    }
    return null;
  }
  //Show/Hide tooltips on mouseover and mouseleave
  private showTooltip(tooltip: any, state: boolean) {
    if (tooltip === "dayDewPoint") {
      this.setState({ ShowDayDewPointToolTip: state });
    }
    if (tooltip === "poolApplicationType") {
      this.setState({ ShowPoolApplicationTypeToolTip: state });
    }
    if (tooltip === "poolConstructionType") {
      this.setState({ ShowPoolContructionTypeToolTip: state });
    }
    if (tooltip === "spaceVolume") {
      this.setState({ ShowSpaceVolumeToolTip: state });
    }
    if (tooltip === "airChangesPerHour") {
      this.setState({ ShowAirChangesPerHourToolTip: state });
    }
    if (tooltip === "deckArea") {
      this.setState({ ShowDeckAreaToolTip: state });
    }
    if (tooltip === "winterDesignDayDryBulb") {
      this.setState({ ShowWinterDesignDayDryBulbToolTip: state });
    }
    if (tooltip === "evapMeanCoincidentDryBulb") {
      this.setState({ ShowEvapMeanCoincidentDryBulbToolTip: state });
    }
    if (tooltip === "evapWetBulb") {
      this.setState({ ShowEvapWetBulbToolTip: state });
    }
    if (tooltip === "designDayDryBulb") {
      this.setState({ ShowDesignDayDryBulbToolTip: state });
    }
    if (tooltip === "dryBulbMeanCoincidentWetBulb") {
      this.setState({ ShowDryBulbMeanCoincidentWetBulbToolTip: state });
    }
    if (tooltip === "summerSpaceLoads") {
      this.setState({ ShowSummerSpaceLoadsToolTip: state });
    }
    if (tooltip === "winterSpaceLoads") {
      this.setState({ ShowWinterSpaceLoadsToolTip: state });
    }
    if (tooltip === "purgeMode") {
      this.setState({ ShowPurgeModeToolTip: state });
    }
    if (tooltip === "seatsQuantity") {
      this.setState({ ShowSeatsQuantityToolTip: state });
    }
    if (tooltip === "seatingArea") {
      this.setState({ ShowSeatingAreaToolTip: state });
    }
    if (tooltip === "seatingIncludedInPoolDeckArea") {
      this.setState({ ShowSeatingIncludedInPoolDeckAreaToolTip: state });
    }
    if (tooltip === "poolSpaceAirTemperature") {
      this.setState({ ShowPoolSpaceAirTemperatureToolTip: state });
    }
    if (tooltip === "summerIndoorRelativeHumidity") {
      this.setState({ ShowSummerIndoorRelativeHumidityToolTip: state });
    }
    if (tooltip === "winterIndoorRelativeHumidity") {
      this.setState({ ShowWinterIndoorRelativeHumidityToolTip: state });
    }
  }
  public render() {
    const poolSpaceValues = this.state.poolSpaceInputs;
    const addButtonClass = this.state.poolFiveInputs ? "pools-add-button-disable" : "";
    const poolsDisplayFields = this.state.poolsDisplayFields;
    const poolSpaceDisplayFields = this.state.poolSpaceDisplayFields;
    if (!poolSpaceValues) {
      return null;
    }
    return (
      <div className="pool-inputs-main">
        <div className="pools-input-container">
          <div className="pool-input-heading">Pool Information</div>
          <div className="pool-heading-wrapper">
            <div className={"pools-add-btn " + addButtonClass}>
              <div onClick={() => this.addPool()}>+ Add Pool</div>
            </div>
            <div className="pool-label pool-bottom-align">Pool Type</div>
            <div className="pool-label">Pool Surface Area (ft&#178;)</div>
            <div className="pool-label">Water Design Temp (&deg;F)</div>
            <div className="pool-label pool-bottom-align">Pool Features</div>
            <div className="pool-label pool-bottom-align">Activity Factor</div>
          </div>

          <SinglePoolInput
            targetVertex={"Pool" + this.state.poolOneInputs.poolIndex + "Inputs"}
            displayFields={this.state.poolsDisplayFields}
            validationErrors={this.state.poolValidationErrors}
            poolData={this.state.poolOneInputs}
            dropdownOptions={this.state.dropdownOptions}
            isFirstPool={true}
            key={this.state.poolOneInputs.poolIndex}
            match={this.props.match}
            deletePool={this.deletePool} //pass a function reference to the SinglePoolInputs
            //runPoolSpaceValidations={this.runPoolSpaceValidations}
          />

          {poolsDisplayFields.showPoolTwo === true ? (
            <SinglePoolInput
              targetVertex={"Pool" + this.state.poolTwoInputs.poolIndex + "Inputs"}
              displayFields={this.state.poolsDisplayFields}
              validationErrors={this.state.poolValidationErrors}
              poolData={this.state.poolTwoInputs}
              dropdownOptions={this.state.dropdownOptions}
              isFirstPool={false}
              key={this.state.poolTwoInputs.poolIndex}
              match={this.props.match}
              deletePool={this.deletePool} //pass a function reference to the SinglePoolInputs
              //runPoolSpaceValidations={this.runPoolSpaceValidations}
            />
          ) : null}

          {poolsDisplayFields.showPoolThree ? (
            <SinglePoolInput
              targetVertex={"Pool" + this.state.poolThreeInputs.poolIndex + "Inputs"}
              displayFields={this.state.poolsDisplayFields}
              validationErrors={this.state.poolValidationErrors}
              poolData={this.state.poolThreeInputs}
              dropdownOptions={this.state.dropdownOptions}
              isFirstPool={false}
              key={this.state.poolThreeInputs.poolIndex}
              match={this.props.match}
              deletePool={this.deletePool} //pass a function reference to the SinglePoolInputs
              //runPoolSpaceValidations={this.runPoolSpaceValidations}
            />
          ) : null}

          {poolsDisplayFields.showPoolFour ? (
            <SinglePoolInput
              targetVertex={"Pool" + this.state.poolFourInputs.poolIndex + "Inputs"}
              displayFields={this.state.poolsDisplayFields}
              validationErrors={this.state.poolValidationErrors}
              poolData={this.state.poolFourInputs}
              dropdownOptions={this.state.dropdownOptions}
              isFirstPool={false}
              key={this.state.poolFourInputs.poolIndex}
              match={this.props.match}
              deletePool={this.deletePool} //pass a function reference to the SinglePoolInputs
              //runPoolSpaceValidations={this.runPoolSpaceValidations}
            />
          ) : null}

          {poolsDisplayFields.showPoolFive ? (
            <SinglePoolInput
              targetVertex={"Pool" + this.state.poolFiveInputs.poolIndex + "Inputs"}
              displayFields={this.state.poolsDisplayFields}
              validationErrors={this.state.poolValidationErrors}
              poolData={this.state.poolFiveInputs}
              dropdownOptions={this.state.dropdownOptions}
              isFirstPool={false}
              key={this.state.poolFiveInputs.poolIndex}
              match={this.props.match}
              deletePool={this.deletePool} //pass a function reference to the SinglePoolInputs
              //runPoolSpaceValidations={this.runPoolSpaceValidations}
            />
          ) : null}

          <div className="pool-spacer"></div>
          <div className="pool-section-grid">
            <div>
              <div className="pool-input-heading2">Job Information</div>
              <div className="pool-input-fields">
                <div className="pool-project-info-grid">
                  <div>Dew Point Day Dew Point (&#176;F)</div>
                  <div>
                    <input
                      type="text"
                      className={this.getCSS("dayDewPoint", "poolInput")}
                      value={formatNumber(poolSpaceValues?.dayDewPoint, 1)}
                      onChange={(event) => this.updatePoolSpaceTextboxValue("dayDewPoint", event.currentTarget.value)}
                      onBlur={(event) => this.saveTextboxValue("dayDewPoint", event.currentTarget.value)}
                    />
                    <span className="app-required"> *</span>
                    <span
                      className="pool-tooltip-info"
                      onMouseEnter={() => this.showTooltip("dayDewPoint", true)}
                      onMouseLeave={() => this.showTooltip("dayDewPoint", false)}
                    ></span>
                    {getTooltipContent("dayDewPoint", this.state.ShowDayDewPointToolTip)}
                    {this.getValidationError("dayDewPoint")}
                  </div>
                  <div>Pool Application Type</div>
                  <div>
                    <select
                      className={this.getCSS("poolApplicationType", "selectbox")}
                      value={poolSpaceValues?.poolApplicationType}
                      onChange={(event) => this.saveInputValue("poolApplicationType", event.currentTarget.value)}
                    >
                      {getSelectBoxOptions(this.state.dropdownOptions, "poolApplicationTypeOptions")}
                    </select>
                    <span className="app-required"> *</span>
                    <span
                      className="pool-tooltip-info"
                      onMouseEnter={() => this.showTooltip("poolApplicationType", true)}
                      onMouseLeave={() => this.showTooltip("poolApplicationType", false)}
                    ></span>
                    {getTooltipContent("poolApplicationType", this.state.ShowPoolApplicationTypeToolTip)}
                    {this.getValidationError("poolApplicationType")}
                  </div>
                  <div>Pool Construction Type</div>
                  <div>
                    <select
                      className={this.getCSS("poolConstructionType", "selectbox")}
                      value={poolSpaceValues?.poolConstructionType}
                      onChange={(event) => this.saveInputValue("poolConstructionType", event.currentTarget.value)}
                    >
                      {getSelectBoxOptions(this.state.dropdownOptions, "poolConstructionTypeOptions")}
                    </select>
                    <span
                      className="pool-tooltip-info pool-info-bubble-adjustment"
                      onMouseEnter={() => this.showTooltip("poolConstructionType", true)}
                      onMouseLeave={() => this.showTooltip("poolConstructionType", false)}
                    ></span>
                    {getTooltipContent("poolConstructionType", this.state.ShowPoolContructionTypeToolTip)}
                    {this.getValidationError("poolConstructionType")}
                  </div>
                  <div>Elevation (ft)</div>
                  <div>
                    <input
                      type="text"
                      className={this.getCSS("elevation", "poolInput")}
                      value={poolSpaceValues?.elevation}
                      onChange={(event) => this.updatePoolSpaceTextboxValue("elevation", event.currentTarget.value)}
                      onBlur={(event) => this.saveTextboxValue("elevation", event.currentTarget.value)}
                    />
                    <span className="app-required"> *</span>
                    {this.getValidationError("elevation")}
                  </div>
                </div>
              </div>
              <div className="pool-spacer"></div>
              <div className="pool-input-heading2">Space Loads</div>
              <div className="pool-input-fields">
                <div className="pool-input-label">Summer Space Loads (btu/hr)</div>
                <span
                  className="pool-tooltip-info"
                  onMouseEnter={() => this.showTooltip("summerSpaceLoads", true)}
                  onMouseLeave={() => this.showTooltip("summerSpaceLoads", false)}
                ></span>
                {getTooltipContent("summerSpaceLoads", this.state.ShowSummerSpaceLoadsToolTip)}

                <div className="pool-summer-space-grid">
                  <div>Walls</div>
                  <div>
                    <input
                      type="text"
                      className={this.getCSS("summerSpaceLoadsWall", "poolInputSmall")}
                      value={poolSpaceValues?.summerSpaceLoadsWall || ""}
                      onChange={(event) => this.updatePoolSpaceTextboxValue("summerSpaceLoadsWall", event.currentTarget.value)}
                      onBlur={(event) => this.saveTextboxValue("summerSpaceLoadsWall", event.currentTarget.value)}
                    />
                    {this.getValidationError("summerSpaceLoadsWall", "validation-pool-space-loads")}
                  </div>
                  <div>Roof</div>
                  <div>
                    <input
                      type="text"
                      className={this.getCSS("summerSpaceLoadsRoof", "poolInputSmall")}
                      value={poolSpaceValues?.summerSpaceLoadsRoof || ""}
                      onChange={(event) => this.updatePoolSpaceTextboxValue("summerSpaceLoadsRoof", event.currentTarget.value)}
                      onBlur={(event) => this.saveTextboxValue("summerSpaceLoadsRoof", event.currentTarget.value)}
                    />
                    {this.getValidationError("summerSpaceLoadsRoof", "validation-pool-space-loads")}
                  </div>
                  <div>Solar</div>
                  <div>
                    <input
                      type="text"
                      className={this.getCSS("summerSpaceLoadsSolar", "poolInputSmall")}
                      value={poolSpaceValues?.summerSpaceLoadsSolar || ""}
                      onChange={(event) => this.updatePoolSpaceTextboxValue("summerSpaceLoadsSolar", event.currentTarget.value)}
                      onBlur={(event) => this.saveTextboxValue("summerSpaceLoadsSolar", event.currentTarget.value)}
                    />
                    {this.getValidationError("summerSpaceLoadsSolar", "validation-pool-space-loads")}
                  </div>
                  <div>Lighting</div>
                  <div>
                    <input
                      type="text"
                      className={this.getCSS("summerSpaceLoadsLighting", "poolInputSmall")}
                      value={poolSpaceValues?.summerSpaceLoadsLighting || ""}
                      onChange={(event) => this.updatePoolSpaceTextboxValue("summerSpaceLoadsLighting", event.currentTarget.value)}
                      onBlur={(event) => this.saveTextboxValue("summerSpaceLoadsLighting", event.currentTarget.value)}
                    />
                    {this.getValidationError("summerSpaceLoadsLighting", "validation-pool-space-loads")}
                  </div>
                </div>
                <div className="pool-input-label">Winter Space Loads (btu/hr)</div>
                <span
                  className="pool-tooltip-info"
                  onMouseEnter={() => this.showTooltip("winterSpaceLoads", true)}
                  onMouseLeave={() => this.showTooltip("winterSpaceLoads", false)}
                ></span>
                {getTooltipContent("winterSpaceLoads", this.state.ShowWinterSpaceLoadsToolTip)}
                <div className="pool-winter-space-grid">
                  <div>Walls</div>
                  <div>
                    <input
                      type="text"
                      className={this.getCSS("winterSpaceLoadsWalls", "poolInputSmall")}
                      value={poolSpaceValues?.winterSpaceLoadsWalls || ""}
                      onChange={(event) => this.updatePoolSpaceTextboxValue("winterSpaceLoadsWalls", event.currentTarget.value)}
                      onBlur={(event) => this.saveTextboxValue("winterSpaceLoadsWalls", event.currentTarget.value)}
                    />
                    {this.getValidationError("winterSpaceLoadsWalls", "validation-pool-space-loads")}
                  </div>
                  <div>Roof</div>
                  <div>
                    <input
                      type="text"
                      className={this.getCSS("winterSpaceLoadsRoof", "poolInputSmall")}
                      value={poolSpaceValues?.winterSpaceLoadsRoof || ""}
                      onChange={(event) => this.updatePoolSpaceTextboxValue("winterSpaceLoadsRoof", event.currentTarget.value)}
                      onBlur={(event) => this.saveTextboxValue("winterSpaceLoadsRoof", event.currentTarget.value)}
                    />
                    {this.getValidationError("winterSpaceLoadsRoof", "validation-pool-space-loads")}
                  </div>
                </div>
                <div className="pool-input-grid">
                  <div>Purge Mode</div>
                  <div>
                    <select
                      className={this.getCSS("purgeMode", "selectbox")}
                      value={poolSpaceValues?.purgeMode}
                      onChange={(event) => this.saveInputValue("purgeMode", event.currentTarget.value)}
                    >
                      {getSelectBoxOptions(this.state.dropdownOptions, "poolPurgeModeOptions")}
                    </select>
                    <span
                      className="pool-tooltip-info pool-info-bubble-adjustment"
                      onMouseEnter={() => this.showTooltip("purgeMode", true)}
                      onMouseLeave={() => this.showTooltip("purgeMode", false)}
                    ></span>
                    {getTooltipContent("purgeMode", this.state.ShowPurgeModeToolTip)}
                  </div>
                </div>
                <div className="pool-input-grid">
                  <div># of Spectator Seats</div>
                  <div>
                    <input
                      type="text"
                      className={this.getCSS("seatsQuantity", "poolInput")}
                      value={poolSpaceValues?.seatsQuantity || ""}
                      onChange={(event) => this.updatePoolSpaceTextboxValue("seatsQuantity", event.currentTarget.value)}
                      onBlur={(event) => this.saveTextboxValue("seatsQuantity", event.currentTarget.value)}
                    />
                    <span
                      className="pool-tooltip-info pool-info-bubble-adjustment"
                      onMouseEnter={() => this.showTooltip("seatsQuantity", true)}
                      onMouseLeave={() => this.showTooltip("seatsQuantity", false)}
                    ></span>
                    {getTooltipContent("seatsQuantity", this.state.ShowSeatsQuantityToolTip)}
                    {this.getValidationError("seatsQuantity")}
                  </div>
                </div>
                <div className="pool-input-grid">
                  <div>Spectator Seating Area (ft&#178;)</div>
                  <div>
                    <input
                      type="text"
                      className={this.getCSS("seatingArea", "poolInput")}
                      value={poolSpaceValues?.seatingArea || ""}
                      onChange={(event) => this.updatePoolSpaceTextboxValue("seatingArea", event.currentTarget.value)}
                      onBlur={(event) => this.saveTextboxValue("seatingArea", event.currentTarget.value)}
                    />
                    <span
                      className="pool-tooltip-info pool-info-bubble-adjustment"
                      onMouseEnter={() => this.showTooltip("seatingArea", true)}
                      onMouseLeave={() => this.showTooltip("seatingArea", false)}
                    ></span>
                    {getTooltipContent("seatingArea", this.state.ShowSeatingAreaToolTip)}
                    {this.getValidationError("seatingArea")}
                  </div>
                </div>
                <div className="pool-input-grid">
                  <div>Spectator Seating Included in Pool/Deck Area?</div>
                  <div>
                    <input
                      className=""
                      type="checkbox"
                      checked={poolSpaceValues?.seatingIncludedInPoolDeckArea}
                      onChange={(event) => this.saveInputValue("seatingIncludedInPoolDeckArea", event.currentTarget.checked.toString())}
                    />
                    <span
                      className="pool-tooltip-info"
                      onMouseEnter={() => this.showTooltip("seatingIncludedInPoolDeckArea", true)}
                      onMouseLeave={() => this.showTooltip("seatingIncludedInPoolDeckArea", false)}
                    ></span>
                    {getTooltipContent("seatingIncludedInPoolDeckArea", this.state.ShowSeatingIncludedInPoolDeckAreaToolTip)}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="pool-input-heading2">Airflow and Design Temps</div>
              <div className="pool-input-fields">
                <div className="pool-input-grid">
                  <div>Space Volume (ft&#179;)</div>
                  <div>
                    <input
                      type="text"
                      className={this.getCSS("spaceVolume", "poolInput")}
                      value={poolSpaceValues?.spaceVolume || ""}
                      onChange={(event) => this.updatePoolSpaceTextboxValue("spaceVolume", event.currentTarget.value)}
                      onBlur={(event) => this.saveTextboxValue("spaceVolume", event.currentTarget.value)}
                    />
                    <span className="app-required"> *</span>
                    <span
                      className="pool-tooltip-info"
                      onMouseEnter={() => this.showTooltip("spaceVolume", true)}
                      onMouseLeave={() => this.showTooltip("spaceVolume", false)}
                    ></span>
                    {getTooltipContent("spaceVolume", this.state.ShowSpaceVolumeToolTip)}
                    {this.getValidationError("spaceVolume")}
                  </div>
                </div>
                <div className="pool-input-grid">
                  <div>Air Changes Per Hour</div>
                  <div>
                    <input
                      disabled={poolSpaceDisplayFields.disableAirChangesPerHour}
                      type="text"
                      className={this.getCSS("airChangesPerHour", "poolInput")}
                      value={poolSpaceValues?.airChangesPerHour}
                      onChange={(event) => this.updatePoolSpaceTextboxValue("airChangesPerHour", event.currentTarget.value)}
                      onBlur={(event) => this.saveTextboxValue("airChangesPerHour", event.currentTarget.value)}
                    />
                    <span className="app-required"> *</span>
                    <span
                      className="pool-tooltip-info"
                      onMouseEnter={() => this.showTooltip("airChangesPerHour", true)}
                      onMouseLeave={() => this.showTooltip("airChangesPerHour", false)}
                    ></span>
                    {getTooltipContent("airChangesPerHour", this.state.ShowAirChangesPerHourToolTip)}
                    {this.getValidationError("airChangesPerHour")}
                  </div>
                </div>
                <div className="pool-input-grid">
                  <div>Deck Area (ft&#178;)</div>
                  <div>
                    <input
                      type="text"
                      className={this.getCSS("deckArea", "poolInput")}
                      value={poolSpaceValues?.deckArea || ""}
                      onChange={(event) => this.updatePoolSpaceTextboxValue("deckArea", event.currentTarget.value)}
                      onBlur={(event) => this.saveTextboxValue("deckArea", event.currentTarget.value)}
                    />
                    <span className="app-required"> *</span>
                    <span
                      className="pool-tooltip-info"
                      onMouseEnter={() => this.showTooltip("deckArea", true)}
                      onMouseLeave={() => this.showTooltip("deckArea", false)}
                    ></span>
                    {getTooltipContent("deckArea", this.state.ShowDeckAreaToolTip)}
                    {this.getValidationError("deckArea")}
                  </div>
                </div>
                <div className="pool-input-grid">
                  <div>Winter Design Day Dry Bulb (&#176;F)</div>
                  <div>
                    <input
                      type="text"
                      className={this.getCSS("winterDesignDayDryBulb", "poolInput")}
                      value={formatNumber(poolSpaceValues?.winterDesignDayDryBulb, 1)}
                      onChange={(event) => this.updatePoolSpaceTextboxValue("winterDesignDayDryBulb", event.currentTarget.value)}
                      onBlur={(event) => this.saveTextboxValue("winterDesignDayDryBulb", event.currentTarget.value)}
                    />
                    <span className="app-required"> *</span>
                    <span
                      className="pool-tooltip-info"
                      onMouseEnter={() => this.showTooltip("winterDesignDayDryBulb", true)}
                      onMouseLeave={() => this.showTooltip("winterDesignDayDryBulb", false)}
                    ></span>
                    {getTooltipContent("winterDesignDayDryBulb", this.state.ShowWinterDesignDayDryBulbToolTip)}
                    {this.getValidationError("winterDesignDayDryBulb")}
                  </div>
                </div>
                {poolSpaceDisplayFields.showDesignDayDryBulb ? (
                  <div className="pool-input-grid">
                    <div>Dry Bulb Design Day Dry Bulb (&#176;F)</div>
                    <div>
                      <input
                        type="text"
                        className={this.getCSS("designDayDryBulb", "poolInput")}
                        value={formatNumber(poolSpaceValues?.designDayDryBulb, 1)}
                        onChange={(event) => this.updatePoolSpaceTextboxValue("designDayDryBulb", event.currentTarget.value)}
                        onBlur={(event) => this.saveTextboxValue("designDayDryBulb", event.currentTarget.value)}
                      />
                      <span className="app-required"> *</span>
                      <span
                        className="pool-tooltip-info"
                        onMouseEnter={() => this.showTooltip("designDayDryBulb", true)}
                        onMouseLeave={() => this.showTooltip("designDayDryBulb", false)}
                      ></span>
                      {getTooltipContent("designDayDryBulb", this.state.ShowDesignDayDryBulbToolTip)}
                      {this.getValidationError("designDayDryBulb")}
                    </div>
                  </div>
                ) : null}
                {poolSpaceDisplayFields.showDryBulbMeanCoincidentWetBulb ? (
                  <div className="pool-input-grid">
                    <div>Dry Bulb Design Day Mean Coincident Wet Bulb (&#176;F)</div>
                    <div>
                      <input
                        type="text"
                        className={this.getCSS("dryBulbMeanCoincidentWetBulb", "poolInput")}
                        value={formatNumber(poolSpaceValues?.dryBulbMeanCoincidentWetBulb, 1)}
                        onChange={(event) => this.updatePoolSpaceTextboxValue("dryBulbMeanCoincidentWetBulb", event.currentTarget.value)}
                        onBlur={(event) => this.saveTextboxValue("dryBulbMeanCoincidentWetBulb", event.currentTarget.value)}
                      />
                      <span className="app-required"> *</span>
                      <span
                        className="pool-tooltip-info"
                        onMouseEnter={() => this.showTooltip("dryBulbMeanCoincidentWetBulb", true)}
                        onMouseLeave={() => this.showTooltip("dryBulbMeanCoincidentWetBulb", false)}
                      ></span>
                      {getTooltipContent("dryBulbMeanCoincidentWetBulb", this.state.ShowDryBulbMeanCoincidentWetBulbToolTip)}
                      {this.getValidationError("dryBulbMeanCoincidentWetBulb")}
                    </div>
                  </div>
                ) : null}
                {poolSpaceDisplayFields.showEvapMeanCoincidentDryBulb ? (
                  <div className="pool-input-grid">
                    <div>Evaporation Design Day Mean Coincident Dry Bulb (&#176;F)</div>
                    <div>
                      <input
                        type="text"
                        className={this.getCSS("evapMeanCoincidentDryBulb", "poolInput")}
                        value={formatNumber(poolSpaceValues?.evapMeanCoincidentDryBulb, 1)}
                        onChange={(event) => this.updatePoolSpaceTextboxValue("evapMeanCoincidentDryBulb", event.currentTarget.value)}
                        onBlur={(event) => this.saveTextboxValue("evapMeanCoincidentDryBulb", event.currentTarget.value)}
                      />
                      <span className="app-required"> *</span>
                      <span
                        className="pool-tooltip-info"
                        onMouseEnter={() => this.showTooltip("evapMeanCoincidentDryBulb", true)}
                        onMouseLeave={() => this.showTooltip("evapMeanCoincidentDryBulb", false)}
                      ></span>
                      {getTooltipContent("evapMeanCoincidentDryBulb", this.state.ShowEvapMeanCoincidentDryBulbToolTip)}
                      {this.getValidationError("evapMeanCoincidentDryBulb")}
                    </div>
                  </div>
                ) : null}
                {poolSpaceDisplayFields.showEvapWetBulb ? (
                  <div className="pool-input-grid">
                    <div>Evaporation Design Day Wet Bulb (&#176;F)</div>
                    <div>
                      <input
                        type="text"
                        className={this.getCSS("evapWetBulb", "poolInput")}
                        value={formatNumber(poolSpaceValues?.evapWetBulb, 1)}
                        onChange={(event) => this.updatePoolSpaceTextboxValue("evapWetBulb", event.currentTarget.value)}
                        onBlur={(event) => this.saveTextboxValue("evapWetBulb", event.currentTarget.value)}
                      />
                      <span className="app-required"> *</span>
                      <span
                        className="pool-tooltip-info"
                        onMouseEnter={() => this.showTooltip("evapWetBulb", true)}
                        onMouseLeave={() => this.showTooltip("evapWetBulb", false)}
                      ></span>
                      {getTooltipContent("evapWetBulb", this.state.ShowEvapWetBulbToolTip)}
                      {this.getValidationError("evapWetBulb")}
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="pool-spacer"></div>
              <div className="pool-spacer"></div>
              <div className="pool-calc-btn" onClick={() => this.calculatePoolValues()}>
                Calculate Pool Values
              </div>
              <div>
                <div className="pool-spacer"></div>
                <div className="pool-calc-value">Pool Air Design Temp (&#176;F)</div>
                <input
                  type="text"
                  disabled={true}
                  className={this.getCSS("summerSpaceLoadsWall", "poolInputSmall")}
                  value={formatNumber(poolSpaceValues?.poolSpaceAirTemperature, 1)}
                />
                <span
                  className="pool-tooltip-info pool-info-bubble-adjustment"
                  onMouseEnter={() => this.showTooltip("poolSpaceAirTemperature", true)}
                  onMouseLeave={() => this.showTooltip("poolSpaceAirTemperature", false)}
                ></span>
                {getTooltipContent("poolSpaceAirTemperature", this.state.ShowPoolSpaceAirTemperatureToolTip)}
              </div>
              <div>
                <div className="pool-spacer-small"></div>
                <div className="pool-calc-value">Summer Indoor Relative Humidity %</div>
                <input
                  type="text"
                  disabled={true}
                  className={this.getCSS("summerIndoorRelativeHumidity", "poolInputSmall")}
                  value={poolSpaceValues?.summerIndoorRelativeHumidity === null ? "" : formatNumber(poolSpaceValues?.summerIndoorRelativeHumidity, 1)}
                />
                <span
                  className="pool-tooltip-info pool-info-bubble-adjustment"
                  onMouseEnter={() => this.showTooltip("summerIndoorRelativeHumidity", true)}
                  onMouseLeave={() => this.showTooltip("summerIndoorRelativeHumidity", false)}
                ></span>
                {getTooltipContent("summerIndoorRelativeHumidity", this.state.ShowSummerIndoorRelativeHumidityToolTip)}
                {this.getValidationError("summerIndoorRelativeHumidity")}
              </div>
              <div>
                <div className="pool-spacer-small"></div>
                <div className="pool-calc-value">Winter Indoor Relative Humidity %</div>
                <input
                  type="text"
                  disabled={true}
                  className={this.getCSS("winterIndoorRelativeHumidity", "poolInputSmall")}
                  value={formatNumber(poolSpaceValues?.winterIndoorRelativeHumidity, 1)}
                />
                <span
                  className="pool-tooltip-info pool-info-bubble-adjustment"
                  onMouseEnter={() => this.showTooltip("winterIndoorRelativeHumidity", true)}
                  onMouseLeave={() => this.showTooltip("winterIndoorRelativeHumidity", false)}
                ></span>
                {getTooltipContent("winterIndoorRelativeHumidity", this.state.ShowWinterIndoorRelativeHumidityToolTip)}
                {this.getValidationError("winterIndoorRelativeHumidity")}
              </div>
              <div>
                <div className="pool-spacer-small"></div>
                <div className="pool-calc-value">Calculated Average Space Height (ft)</div>
                <input
                  type="text"
                  disabled={true}
                  className={this.getCSS("spaceHeight", "poolInputSmall")}
                  value={formatNumber(poolSpaceValues?.spaceHeight, 0) || ""}
                />
                {this.getValidationError("spaceHeight")}
              </div>
              {this.state.showPrerequisiteErrors ? (
                <div className="pool-prereq">
                  <div className="pool-prereq-msg">Pool calculations cannot be run due to these missing/invalid fields:</div>
                  {this.getPrerequisiteErrors()}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//------------------ Redux ----------------------------
function mapStateToProps(state: any) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(PoolInputs);
